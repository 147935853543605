var _excluded = ["children", "className", "type", "truncate"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { EuiBreadcrumbContent } from './_breadcrumb_content';
import { euiBreadcrumbStyles } from './breadcrumb.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiBreadcrumb = function EuiBreadcrumb(_ref) {
  var children = _ref.children,
    className = _ref.className,
    type = _ref.type,
    truncate = _ref.truncate,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiBreadcrumb', className);
  var styles = useEuiMemoizedStyles(euiBreadcrumbStyles);
  var cssStyles = [styles.euiBreadcrumb, styles[type], truncate && styles.isTruncated];
  return ___EmotionJSX("li", _extends({
    className: classes,
    css: cssStyles,
    "data-test-subj": "euiBreadcrumb"
  }, rest), children);
};
export var EuiBreadcrumbCollapsed = function EuiBreadcrumbCollapsed(_ref2) {
  var children = _ref2.children,
    isFirstBreadcrumb = _ref2.isFirstBreadcrumb,
    type = _ref2.type;
  var styles = useEuiMemoizedStyles(euiBreadcrumbStyles);
  var cssStyles = [styles.isCollapsed];
  var ariaLabel = useEuiI18n('euiBreadcrumb.collapsedBadge.ariaLabel', 'See collapsed breadcrumbs');
  return ___EmotionJSX(EuiBreadcrumb, {
    css: cssStyles,
    type: type
  }, ___EmotionJSX(EuiBreadcrumbContent, {
    popoverContent: children,
    text: ___EmotionJSX("span", {
      "aria-label": ariaLabel
    }, "\u2026"),
    title: ariaLabel,
    truncate: false,
    isFirstBreadcrumb: isFirstBreadcrumb,
    type: type
  }));
};