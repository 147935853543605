/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { useEuiTheme } from '../../services/theme';
import { transparentize } from '../../services/color';
import { logicalCSS, logicalCSSWithFallback } from '../functions';

/**
 * Set scroll bar appearance on Chrome (and firefox).
 * All parameters are optional and default to specific global settings.
 */

export var euiScrollBarStyles = function euiScrollBarStyles(_ref) {
  var _ref$euiTheme = _ref.euiTheme,
    colors = _ref$euiTheme.colors,
    size = _ref$euiTheme.size;
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _thumbColor = _ref2.thumbColor,
    _ref2$trackColor = _ref2.trackColor,
    trackColor = _ref2$trackColor === void 0 ? 'transparent' : _ref2$trackColor,
    _ref2$width = _ref2.width,
    width = _ref2$width === void 0 ? 'thin' : _ref2$width,
    _size = _ref2.size,
    _corner = _ref2.corner;
  // Set defaults from theme
  var thumbColor = _thumbColor || transparentize(colors.darkShade, 0.5);
  var scrollBarSize = _size || size.base;
  var scrollBarCorner = _corner || width === 'thin' ? "calc(".concat(size.s, " * 0.75)") : size.xs;

  // Firefox's scrollbar coloring cascades, but the sizing does not,
  // so it's being added to this mixin for allowing support wherever custom scrollbars are
  var firefoxSupport = "scrollbar-color: ".concat(thumbColor, " ").concat(trackColor, ";");
  return "scrollbar-width: ".concat(width, ";\n\n    &::-webkit-scrollbar {\n      ").concat(logicalCSS('width', scrollBarSize), "\n      ").concat(logicalCSS('height', scrollBarSize), "\n    }\n\n    &::-webkit-scrollbar-thumb {\n      background-color: ").concat(thumbColor, ";\n      background-clip: content-box;\n      border-radius: ").concat(scrollBarSize, ";\n      border: ").concat(scrollBarCorner, " solid ").concat(trackColor, ";\n    }\n\n    &::-webkit-scrollbar-corner,\n    &::-webkit-scrollbar-track {\n      background-color: ").concat(trackColor, ";\n    }\n\n    ").concat(firefoxSupport, "\n  ");
};
export var useEuiScrollBar = function useEuiScrollBar(options) {
  var euiTheme = useEuiTheme();
  return euiScrollBarStyles(euiTheme, options);
};

/**
 * *INTERNAL*
 * Overflow shadow masks for use in YScroll and XScroll helpers
 */

var euiOverflowShadowStyles = function euiOverflowShadowStyles(_ref3) {
  var size = _ref3.euiTheme.size;
  var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _direction = _ref4.direction,
    _side = _ref4.side;
  var direction = _direction || 'y';
  var side = _side || 'both';
  var hideHeight = size.s;
  var gradientStart = "\n  ".concat(transparentize('red', 0.1), " 0%,\n  ").concat(transparentize('red', 1), " ").concat(hideHeight, "\n  ");
  var gradientEnd = "\n  ".concat(transparentize('red', 1), " calc(100% - ").concat(hideHeight, "),\n  ").concat(transparentize('red', 0.1), " 100%\n  ");
  var gradient = '';
  if (side) {
    if (side === 'both') {
      gradient = "".concat(gradientStart, ", ").concat(gradientEnd);
    } else if (side === 'start') {
      gradient = "".concat(gradientStart);
    } else {
      gradient = "".concat(gradientEnd);
    }
  }

  // Chrome+Edge has a very bizarre edge case bug where `mask-image` stops working
  // This workaround forces a stacking context on the scrolling container, which
  // hopefully addresses the bug. @see:
  // - https://issues.chromium.org/issues/40778541
  // - https://github.com/elastic/kibana/issues/180828
  // - https://github.com/elastic/eui/pull/6343#issuecomment-1302732021
  var chromiumMaskWorkaround = 'transform: translateZ(0);';
  if (direction === 'y') {
    return "mask-image: linear-gradient(to bottom, ".concat(gradient, "); ").concat(chromiumMaskWorkaround);
  } else {
    return "mask-image: linear-gradient(to right, ".concat(gradient, "); ").concat(chromiumMaskWorkaround);
  }
};

/**
 * 1. Focus rings shouldn't be visible on scrollable regions, but a11y requires them to be focusable.
 *    Browser's supporting `:focus-visible` will still show outline on keyboard focus only.
 *    Others like Safari, won't show anything at all.
 */

export var euiYScroll = function euiYScroll(euiTheme) {
  var _ref5 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    height = _ref5.height;
  return "\n  ".concat(euiScrollBarStyles(euiTheme), "\n  ").concat(logicalCSS('height', height || '100%'), "\n  ").concat(logicalCSSWithFallback('overflow-y', 'auto'), "\n  ").concat(logicalCSSWithFallback('overflow-x', 'hidden'), "\n  &:focus {\n    outline: none; /* 1 */\n  }\n");
};
export var useEuiYScroll = function useEuiYScroll() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    height = _ref6.height;
  var euiTheme = useEuiTheme();
  return euiYScroll(euiTheme, {
    height: height
  });
};
export var euiYScrollWithShadows = function euiYScrollWithShadows(euiTheme) {
  var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    height = _ref7.height,
    _ref7$side = _ref7.side,
    side = _ref7$side === void 0 ? 'both' : _ref7$side;
  return "\n  ".concat(euiYScroll(euiTheme, {
    height: height
  }), "\n  ").concat(euiOverflowShadowStyles(euiTheme, {
    direction: 'y',
    side: side
  }), "\n");
};
export var useEuiYScrollWithShadows = function useEuiYScrollWithShadows() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    height = _ref8.height;
  var euiTheme = useEuiTheme();
  return euiYScrollWithShadows(euiTheme, {
    height: height
  });
};
export var euiXScroll = function euiXScroll(euiTheme) {
  return "\n  ".concat(euiScrollBarStyles(euiTheme), "\n  ").concat(logicalCSSWithFallback('overflow-x', 'auto'), "\n  &:focus {\n    outline: none; /* 1 */\n  }\n");
};
export var useEuiXScroll = function useEuiXScroll() {
  var euiTheme = useEuiTheme();
  return euiXScroll(euiTheme);
};
export var euiXScrollWithShadows = function euiXScrollWithShadows(euiTheme) {
  return "\n  ".concat(euiXScroll(euiTheme), "\n  ").concat(euiOverflowShadowStyles(euiTheme, {
    direction: 'x'
  }), "\n");
};
export var useEuiXScrollWithShadows = function useEuiXScrollWithShadows() {
  var euiTheme = useEuiTheme();
  return euiXScrollWithShadows(euiTheme);
};
export var euiOverflowScroll = function euiOverflowScroll(euiTheme) {
  var _ref9 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    direction = _ref9.direction,
    _ref9$mask = _ref9.mask,
    mask = _ref9$mask === void 0 ? false : _ref9$mask;
  switch (direction) {
    case 'y':
      return mask ? euiYScrollWithShadows(euiTheme) : euiYScroll(euiTheme);
    case 'x':
      return mask ? euiXScrollWithShadows(euiTheme) : euiXScroll(euiTheme);
    default:
      console.warn('Please provide a valid direction option to useEuiOverflowScroll');
      return '';
  }
};
export var useEuiOverflowScroll = function useEuiOverflowScroll(direction) {
  var mask = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var euiTheme = useEuiTheme();
  return euiOverflowScroll(euiTheme, {
    direction: direction,
    mask: mask
  });
};

/**
 * For quickly applying a full-height element whether using flex or not
 */
export var euiFullHeight = function euiFullHeight() {
  return "\n  ".concat(logicalCSS('height', '100%'), "\n  flex: 1 1 auto;\n  overflow: hidden;\n");
};

/**
 * A constant storing the support for the `:has()` selector through a
 * media query that will only apply the content it is supported.
 */
export var euiSupportsHas = '@supports(selector(:has(p)))';