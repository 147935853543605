var _excluded = ["children", "className", "sticky", "paddingSize", "minWidth", "responsive", "hasEmbellish", "style"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useLayoutEffect, useState } from 'react';
import PropTypes from "prop-types";
import { logicalStyle, useEuiPaddingCSS } from '../../../global_styling';
import { useEuiTheme, useIsWithinBreakpoints } from '../../../services';
import { euiPageSidebarStyles } from './page_sidebar.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiPageSidebar = function EuiPageSidebar(_ref) {
  var children = _ref.children,
    className = _ref.className,
    _ref$sticky = _ref.sticky,
    sticky = _ref$sticky === void 0 ? false : _ref$sticky,
    _ref$paddingSize = _ref.paddingSize,
    paddingSize = _ref$paddingSize === void 0 ? 'none' : _ref$paddingSize,
    _ref$minWidth = _ref.minWidth,
    minWidth = _ref$minWidth === void 0 ? 248 : _ref$minWidth,
    _ref$responsive = _ref.responsive,
    responsive = _ref$responsive === void 0 ? ['xs', 's'] : _ref$responsive,
    _ref$hasEmbellish = _ref.hasEmbellish,
    hasEmbellish = _ref$hasEmbellish === void 0 ? false : _ref$hasEmbellish,
    style = _ref.style,
    rest = _objectWithoutProperties(_ref, _excluded);
  var themeContext = useEuiTheme();
  var styles = euiPageSidebarStyles(themeContext);
  var isResponding = useIsWithinBreakpoints(responsive);
  var cssStyles = [styles.euiPageSidebar, !isResponding && sticky && styles.sticky, hasEmbellish && styles.embellish, useEuiPaddingCSS()[paddingSize]];

  // Inline styles for setting up width and sticky offsets
  var _useState = useState(_objectSpread(_objectSpread({}, style), logicalStyle('min-width', isResponding ? '100%' : minWidth))),
    _useState2 = _slicedToArray(_useState, 2),
    inlineStyles = _useState2[0],
    setInlineStyles = _useState2[1];
  useLayoutEffect(function () {
    var updatedStyles = _objectSpread(_objectSpread({}, style), logicalStyle('min-width', isResponding ? '100%' : minWidth));
    if (sticky) {
      var offset = _typeof(sticky) === 'object' ? "".concat(sticky === null || sticky === void 0 ? void 0 : sticky.offset, "px") : 'var(--euiFixedHeadersOffset, 0)';
      updatedStyles = _objectSpread(_objectSpread(_objectSpread({}, updatedStyles), logicalStyle('top', offset)), logicalStyle('max-height', "calc(100vh - ".concat(offset, ")")));
    }
    setInlineStyles(updatedStyles);
  }, [style, sticky, themeContext.euiTheme.base, isResponding, minWidth]);
  return ___EmotionJSX("div", _extends({
    className: className,
    css: cssStyles,
    style: inlineStyles
  }, rest), children);
};
EuiPageSidebar.propTypes = {
  /**
     * Adjust the padding.
     * When using this setting it's best to be consistent throughout all similar usages.
     */
  paddingSize: PropTypes.any,
  /**
     * Renders a fancy little visual in the top left corner of the side bar
     */
  hasEmbellish: PropTypes.bool,
  /**
     * Adds `position: sticky` and affords for any fixed position headers.
     */
  sticky: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    /**
             * To account for any fixed elements like headers,
             * pass in the value of the total height of those fixed elements.
             */
    offset: PropTypes.number
  }).isRequired]),
  /**
     * A minimum width is necessary to maintain size.
     * Be sure to take `paddingSize` into account.
     */
  minWidth: PropTypes.any,
  /**
     * Sets the `minWidth` to 100% when within these breakpoints.
     */
  responsive: PropTypes.arrayOf(PropTypes.any.isRequired),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};