/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { sortMapBySmallToLargeValues } from '../../services/breakpoint/_sorting';
import { useEuiTheme } from '../../services/theme/hooks';
/**
 * Generates a CSS media query rule string based on the input breakpoint *ranges*.
 * Examples with default theme breakpoints:
 *
 * euiBreakpoint(['s']) becomes `@media only screen and (min-width: 575px) and (max-width: 767px)`
 * euiBreakpoint(['s', 'l']) becomes `@media only screen and (min-width: 575px) and (max-width: 1199px)`
 *
 * Use the smallest and largest sizes to generate media queries with only min/max-width.
 * Examples with default theme breakpoints:
 *
 * euiBreakpoint(['xs', 'm']) becomes `@media only screen and (max-width: 991px)`
 * euiBreakpoint(['l', 'xl']) becomes `@media only screen and (min-width: 992px)`
 */
export var euiBreakpoint = function euiBreakpoint(_ref, sizes) {
  var euiTheme = _ref.euiTheme;
  // Ensure we inherit any theme breakpoint overrides & sort by small to large
  var orderedBreakpoints = Object.keys(sortMapBySmallToLargeValues(euiTheme.breakpoint));

  // Ensure the sizes array is in the correct ascending size order
  var orderedSizes = sizes.sort(function (a, b) {
    return orderedBreakpoints.indexOf(a) - orderedBreakpoints.indexOf(b);
  });
  var firstBreakpoint = orderedSizes[0];
  var minBreakpointSize = euiTheme.breakpoint[firstBreakpoint];
  var lastBreakpoint = orderedSizes[sizes.length - 1];
  var maxBreakpointSize;

  // To get the correct screen range, we set the max-width to the next breakpoint
  // size in the sizes array (unless the size is already the largest breakpoint)
  if (lastBreakpoint !== orderedBreakpoints[orderedBreakpoints.length - 1]) {
    var nextBreakpoint = orderedBreakpoints.indexOf(lastBreakpoint) + 1;
    maxBreakpointSize = euiTheme.breakpoint[orderedBreakpoints[nextBreakpoint]];
  }
  return ['@media only screen', minBreakpointSize ? "(min-width: ".concat(minBreakpointSize, "px)") : false,
  // If 0, don't render a min-width
  maxBreakpointSize ? "(max-width: ".concat(maxBreakpointSize - 1, "px)") : false // If undefined, don't render a max-width
  ].filter(Boolean).join(' and ');
};
export var useEuiBreakpoint = function useEuiBreakpoint(sizes) {
  var euiTheme = useEuiTheme();
  return euiBreakpoint(euiTheme, sizes);
};

/**
 * Min/Max width breakpoint utilities that generate only a single min/max query/bound
 *
 * *Unlike the above euiBreakpoint utility*, these utilities treat breakpoint
 * sizes as a one-dimensional point, rather than a two-dimensional *screen range*.
 * Examples with default theme breakpoints:
 *
 * euiMaxBreakpoint('m') becomes `@media only screen and (max-width: 767px)`
 * euiMinBreakpoint('m') becomes `@media only screen and (min-width: 768px)`
 *
 * This is safer and more intentional to use than euiBreakpoint(['xs', 's']) / euiBreakpoint(['m', 'xl'])
 * in the event that consumers add larger or smaller custom breakpoints (e.g 'xxs' or `xxl`)
 * and if the intention of the media query is actually "m and below/above" vs. "only screens m/l/xl".
 */

export var euiMinBreakpoint = function euiMinBreakpoint(_ref2, size) {
  var euiTheme = _ref2.euiTheme;
  var minBreakpointSize = euiTheme.breakpoint[size];
  if (minBreakpointSize) {
    return "@media only screen and (min-width: ".concat(minBreakpointSize, "px)");
  } else {
    console.warn("Invalid min breakpoint size: ".concat(size));
    return '@media only screen';
  }
};
export var useEuiMinBreakpoint = function useEuiMinBreakpoint(size) {
  var euiTheme = useEuiTheme();
  return euiMinBreakpoint(euiTheme, size);
};
export var euiMaxBreakpoint = function euiMaxBreakpoint(_ref3, size) {
  var euiTheme = _ref3.euiTheme;
  var maxBreakpointSize = euiTheme.breakpoint[size];
  if (maxBreakpointSize) {
    return "@media only screen and (max-width: ".concat(maxBreakpointSize - 1, "px)");
  } else {
    console.warn("Invalid max breakpoint size: ".concat(size));
    return '@media only screen';
  }
};
export var useEuiMaxBreakpoint = function useEuiMaxBreakpoint(size) {
  var euiTheme = useEuiTheme();
  return euiMaxBreakpoint(euiTheme, size);
};