var _excluded = ["children", "title", "className", "positionToolTip", "popoverRef", "calculatedPosition"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { euiToolTipStyles } from './tool_tip.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiToolTipPopover = function EuiToolTipPopover(_ref) {
  var children = _ref.children,
    title = _ref.title,
    className = _ref.className,
    positionToolTip = _ref.positionToolTip,
    popoverRef = _ref.popoverRef,
    calculatedPosition = _ref.calculatedPosition,
    rest = _objectWithoutProperties(_ref, _excluded);
  var popover = useRef();
  var euiTheme = useEuiTheme();
  var styles = euiToolTipStyles(euiTheme);
  var cssStyles = [styles.euiToolTip, calculatedPosition && styles[calculatedPosition]];
  var updateDimensions = useCallback(function () {
    requestAnimationFrame(function () {
      // Because of this delay, sometimes `positionToolTip` becomes unavailable.
      if (popover.current) {
        positionToolTip();
      }
    });
  }, [positionToolTip]);
  var setPopoverRef = function setPopoverRef(ref) {
    if (popoverRef) {
      popoverRef(ref);
    }
  };
  useEffect(function () {
    document.body.classList.add('euiBody-hasPortalContent');
    window.addEventListener('resize', updateDimensions);
    return function () {
      document.body.classList.remove('euiBody-hasPortalContent');
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);
  var classes = classNames('euiToolTipPopover', className);
  return ___EmotionJSX("div", _extends({
    css: cssStyles,
    className: classes,
    ref: setPopoverRef,
    "data-position": calculatedPosition
  }, rest), title && ___EmotionJSX("div", {
    css: styles.euiToolTip__title,
    className: "euiToolTip__title"
  }, title), children);
};
EuiToolTipPopover.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  positionToolTip: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  popoverRef: PropTypes.func,
  calculatedPosition: PropTypes.oneOf(["top", "right", "bottom", "left"])
};