function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { keysOf } from '../../components/common';
import LOGICALS from './logicals.json';

/**
 * EUI utilizes logical CSS properties to enable directional writing-modes.
 * To encourage use of logical properties, we provide a few helper utilities to
 * convert certain directional properties to logical properties.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Logical_Properties
 */

export var logicalSide = {
  left: 'inline-start',
  right: 'inline-end',
  top: 'block-start',
  bottom: 'block-end',
  horizontal: 'inline',
  vertical: 'block'
};
export var LOGICAL_SIDES = keysOf(logicalSide);
export var logicals = LOGICALS;
var _shorthands = LOGICALS._shorthands,
  _logicals = _objectWithoutProperties(LOGICALS, ["_shorthands"]);
export var LOGICAL_PROPERTIES = keysOf(_logicals);
/**
 *
 * @param property A string that is a valid CSS logical property
 * @param value String to output as the property value
 * @returns `string` Returns the logical CSS property version for the given `property: value` pair
 */
export var logicalCSS = function logicalCSS(property, value) {
  return "".concat(logicals[property], ": ").concat(value, ";");
};

/**
 * Some logical properties are not yet fully supported by all browsers.
 * For those cases, we should use the old property as a fallback for
 * browsers missing support, while allowing supporting browsers to use
 * the logical properties.
 *
 * Examples:
 * https://caniuse.com/?search=overflow-block
 * https://caniuse.com/mdn-css_properties_float_flow_relative_values
 */
export var logicalCSSWithFallback = function logicalCSSWithFallback(property, value) {
  return "\n  ".concat(property, ": ").concat(value, ";\n  ").concat(logicalCSS(property, value), "\n");
};

/**
 * Casing utils for swapping between camel case (style objs) and kebab case (CSS)
 */
var camelCase = function camelCase(kebabCasedString) {
  return kebabCasedString.replace(/-\w/g, function (str) {
    return str.charAt(1).toUpperCase();
  });
};
var kebabCase = function kebabCase(camelCasedString) {
  return camelCasedString.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

/**
 *
 * @param property A string that is a valid CSS logical property
 * @param value String to output as the property value
 * @returns `object` Returns the logical CSS property version for the given `property: value` pair
 */
export var logicalStyle = function logicalStyle(property, value) {
  return _defineProperty({}, camelCase(logicals[property]), value);
};

/**
 * Given a style object with any amount of unknown CSS properties,
 * find ones that can be converted to logical properties and convert them
 *
 * @param styleObject - A React object of camelCased styles
 * @returns `object`
 */
export var logicalStyles = function logicalStyles(styleObject) {
  var logicalStyleObject = {};
  Object.entries(styleObject).forEach(function (_ref2) {
    var _ref3 = _slicedToArray(_ref2, 2),
      key = _ref3[0],
      value = _ref3[1];
    var cssProperty = kebabCase(key);
    if (logicals.hasOwnProperty(cssProperty)) {
      var logicalKey = camelCase(logicals[cssProperty]);
      logicalStyleObject[logicalKey] = value;
    } else {
      logicalStyleObject[key] = value;
    }
  });
  return logicalStyleObject;
};

/**
 *
 * @param width A string value for the LTR width
 * @param height A string value for the LTR height
 * @returns `string` Returns the logical CSS properties for height and width
 */
export var logicalSizeCSS = function logicalSizeCSS(width) {
  var height = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : width;
  return "\n    ".concat(logicals.width, ": ").concat(width, ";\n    ").concat(logicals.height, ": ").concat(height, ";\n  ");
};

/**
 *
 * @param width A string value for the LTR width
 * @param height A string value for the LTR height
 * @returns `object` Returns the logical CSS properties for height and width
 */
export var logicalSizeStyle = function logicalSizeStyle(width, height) {
  return _objectSpread(_objectSpread({}, logicalStyle('width', width)), logicalStyle('height', height));
};

// Text alignment is separate because its the value that changes not the property
export var logicalText = {
  'text-align': {
    left: 'start',
    center: 'center',
    right: 'end'
  }
};
export var LOGICAL_TEXT_ALIGNMENT = keysOf(logicalText['text-align']);
/**
 *
 * @param property A string that is a valid CSS logical property
 * @param value String to output as the property value
 * @returns `string` Returns the logical CSS property version for the given `property: value` pair
 */
export var logicalTextAlignCSS = function logicalTextAlignCSS(value) {
  return "text-align: ".concat(logicalText['text-align'][value], ";");
};

/**
 *
 * @param property A string that is a valid CSS logical property
 * @param value String to output as the property value
 * @returns `object` Returns the logical CSS property version for the given `property: value` pair
 */
export var logicalTextAlignStyle = function logicalTextAlignStyle(value) {
  return {
    textAlign: logicalText['text-align'][value]
  };
};