function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { css } from '@emotion/react';
export var euiFlexItemStyles = {
  // 1. Allow EuiPanels to expand to fill the item.
  euiFlexItem: process.env.NODE_ENV === "production" ? {
    name: "19hf44n-euiFlexItem",
    styles: "display:flex;flex-direction:column;label:euiFlexItem;"
  } : {
    name: "19hf44n-euiFlexItem",
    styles: "display:flex;flex-direction:column;label:euiFlexItem;",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
  },
  growZero: process.env.NODE_ENV === "production" ? {
    name: "1pat7n0-growZero",
    styles: "flex-grow:0;flex-basis:auto;label:growZero;"
  } : {
    name: "1pat7n0-growZero",
    styles: "flex-grow:0;flex-basis:auto;label:growZero;",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
  },
  grow: process.env.NODE_ENV === "production" ? {
    name: "tr4wer-grow",
    styles: "flex-basis:0%;label:grow;"
  } : {
    name: "tr4wer-grow",
    styles: "flex-basis:0%;label:grow;",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
  },
  growSizes: {
    '1': process.env.NODE_ENV === "production" ? {
      name: "1v6uyoj-1",
      styles: "flex-grow:1;label:1;"
    } : {
      name: "1v6uyoj-1",
      styles: "flex-grow:1;label:1;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '2': process.env.NODE_ENV === "production" ? {
      name: "1l86xxh-2",
      styles: "flex-grow:2;label:2;"
    } : {
      name: "1l86xxh-2",
      styles: "flex-grow:2;label:2;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '3': process.env.NODE_ENV === "production" ? {
      name: "1sl79ay-3",
      styles: "flex-grow:3;label:3;"
    } : {
      name: "1sl79ay-3",
      styles: "flex-grow:3;label:3;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '4': process.env.NODE_ENV === "production" ? {
      name: "k4pnsg-4",
      styles: "flex-grow:4;label:4;"
    } : {
      name: "k4pnsg-4",
      styles: "flex-grow:4;label:4;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '5': process.env.NODE_ENV === "production" ? {
      name: "1cx5fxs-5",
      styles: "flex-grow:5;label:5;"
    } : {
      name: "1cx5fxs-5",
      styles: "flex-grow:5;label:5;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '6': process.env.NODE_ENV === "production" ? {
      name: "1in9iiy-6",
      styles: "flex-grow:6;label:6;"
    } : {
      name: "1in9iiy-6",
      styles: "flex-grow:6;label:6;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '7': process.env.NODE_ENV === "production" ? {
      name: "fvh3nz-7",
      styles: "flex-grow:7;label:7;"
    } : {
      name: "fvh3nz-7",
      styles: "flex-grow:7;label:7;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '8': process.env.NODE_ENV === "production" ? {
      name: "fjdgzc-8",
      styles: "flex-grow:8;label:8;"
    } : {
      name: "fjdgzc-8",
      styles: "flex-grow:8;label:8;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '9': process.env.NODE_ENV === "production" ? {
      name: "1tyr8p6-9",
      styles: "flex-grow:9;label:9;"
    } : {
      name: "1tyr8p6-9",
      styles: "flex-grow:9;label:9;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    },
    '10': process.env.NODE_ENV === "production" ? {
      name: "n7polf-10",
      styles: "flex-grow:10;label:10;"
    } : {
      name: "n7polf-10",
      styles: "flex-grow:10;label:10;",
      toString: _EMOTION_STRINGIFIED_CSS_ERROR__
    }
  }
};