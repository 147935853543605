var _excluded = ["children", "className"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { euiHeaderSectionItemStyles } from './header_section_item.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
/**
 * Header items are small icon links that pop up menus
 */
export var EuiHeaderSectionItem = function EuiHeaderSectionItem(_ref) {
  var children = _ref.children,
    className = _ref.className,
    rest = _objectWithoutProperties(_ref, _excluded);
  var euiTheme = useEuiTheme();
  var styles = euiHeaderSectionItemStyles(euiTheme);
  var classes = classNames('euiHeaderSectionItem', className);

  // we check if there is any children and if not, we don't render anything
  return children ? ___EmotionJSX("div", _extends({
    className: classes,
    css: styles.euiHeaderSectionItem
  }, rest), children) : null;
};
EuiHeaderSectionItem.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node
};