var _excluded = ["children", "restrictWidth", "className", "css", "component", "panelled", "panelProps", "paddingSize", "borderRadius"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { setStyleForRestrictedPageWidth } from '../_restrict_width';
import { EuiPanel } from '../../panel';
import { useEuiPaddingCSS } from '../../../global_styling';
import { euiPageBodyStyles } from './page_body.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiPageBody = function EuiPageBody(_ref) {
  var children = _ref.children,
    _ref$restrictWidth = _ref.restrictWidth,
    restrictWidth = _ref$restrictWidth === void 0 ? false : _ref$restrictWidth,
    className = _ref.className,
    css = _ref.css,
    _ref$component = _ref.component,
    Component = _ref$component === void 0 ? 'div' : _ref$component,
    panelled = _ref.panelled,
    panelProps = _ref.panelProps,
    _ref$paddingSize = _ref.paddingSize,
    paddingSize = _ref$paddingSize === void 0 ? 'none' : _ref$paddingSize,
    _ref$borderRadius = _ref.borderRadius,
    borderRadius = _ref$borderRadius === void 0 ? 'none' : _ref$borderRadius,
    rest = _objectWithoutProperties(_ref, _excluded);
  // Set max-width as a style prop
  var widthStyles = setStyleForRestrictedPageWidth(restrictWidth, rest === null || rest === void 0 ? void 0 : rest.style);

  // Shared
  var classes = classNames('euiPageBody', className);
  var styles = euiPageBodyStyles();
  var cssStyles = [styles.euiPageBody, restrictWidth && styles.restrictWidth];

  // Panelled
  var panelClasses = classNames(classes, panelProps === null || panelProps === void 0 ? void 0 : panelProps.className);
  var panelCssStyles = [].concat(cssStyles, [panelProps === null || panelProps === void 0 ? void 0 : panelProps.css, css]);

  // Non-panelled
  var padding = useEuiPaddingCSS()[paddingSize];
  var componentCssStyles = [].concat(cssStyles, [padding, css]);
  return panelled ? ___EmotionJSX(EuiPanel, _extends({
    borderRadius: borderRadius,
    paddingSize: paddingSize
  }, panelProps, rest, {
    className: panelClasses,
    css: panelCssStyles
  }), children) : ___EmotionJSX(Component, _extends({}, rest, {
    className: classes,
    css: componentCssStyles,
    style: widthStyles
  }), children);
};