function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import LOGICALS from './logicals.json';
var LOGICAL_SHORTHANDS = LOGICALS._shorthands;
export { LOGICAL_SHORTHANDS };
/**
 * Unfortunately, shorthand properties that describe boxes
 * (@see https://developer.mozilla.org/en-US/docs/Web/CSS/Shorthand_properties#edges_of_a_box)
 * do not currently automatically respond to logical changes in display direction
 * (@see https://github.com/w3c/csswg-drafts/issues/1282)
 *
 * This utility is essentially a stop-gap for those shorthand properties,
 * converting them to corresponding longer logical `-inline` and `-block` properties
 *
 * 🗑 NOTE: This file is in a separate util file from logicals.ts due to its relatively
 * convoluted logic, & to make deleting it easier when an official CSS spec is implemented.
 */
export var logicalShorthandCSS = function logicalShorthandCSS(property, value) {
  if (!LOGICAL_SHORTHANDS.includes(property)) {
    throw new Error("".concat(property, " is not a shorthand property that needs logical CSS"));
  }
  if (property === 'border-radius') {
    return logicalBorderRadiusCSS(String(value));
  }

  // Split all potential values by spaces
  var values = String(value).split(/\s+/);
  var verticalBlockValue;
  var horizontalInlineValue;
  switch (values.length) {
    case 1:
      // If it's the same value all around, no need to use logical properties
      return "".concat(property, ": ").concat(value, ";");
    case 2:
      verticalBlockValue = values[0];
      horizontalInlineValue = values[1];
      break;
    case 3:
      verticalBlockValue = "".concat(values[0], " ").concat(values[2]);
      horizontalInlineValue = values[1];
      break;
    case 4:
    default:
      verticalBlockValue = "".concat(values[0], " ").concat(values[2]);
      horizontalInlineValue = "".concat(values[3], " ").concat(values[1]); // Note: left (4th value) comes before right (2nd value)
      break;
  }
  if (property.includes('border-')) {
    // Border properties have a different naming syntax than margin/padding/etc
    var borderProperty = property.split('-')[1];
    return "\n    border-block-".concat(borderProperty, ": ").concat(verticalBlockValue, ";\n    border-inline-").concat(borderProperty, ": ").concat(horizontalInlineValue, ";\n    ");
  } else {
    return "\n    ".concat(property, "-block: ").concat(verticalBlockValue, ";\n    ").concat(property, "-inline: ").concat(horizontalInlineValue, ";\n    ");
  }
};

/**
 * Logical border radius is unfortunately a very special case as it handles corners
 * and not sides (@see https://developer.mozilla.org/en-US/docs/Web/CSS/Shorthand_properties#corners_of_a_box)
 * and does not have `-inline` or `-block` shorthands.
 *
 * It also needs to account for `/` syntax (horizontal vs vertical radii)
 * @see https://www.sitepoint.com/setting-css3-border-radius-with-slash-syntax/
 */
export var logicalBorderRadiusCSS = function logicalBorderRadiusCSS(value) {
  var ignoreZeroes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var borderRadiusMap = {
    'border-start-start-radius': '',
    'border-start-end-radius': '',
    'border-end-end-radius': '',
    'border-end-start-radius': ''
  };
  var values = [];
  if (value.includes('/')) {
    values = ['', '', '', ''];

    // Split into horizontal & vertical radii strings
    value.split('/').forEach(function (radiiAxes) {
      var radii = radiiAxes.trim().split(/\s+/);
      values.forEach(function (_, i) {
        // Add a space between the horizontal and vertical radius
        var combinedValue = values[i] ? "".concat(values[i], " ") : values[i];
        switch (radii.length) {
          case 1:
            // Every value is repeated
            combinedValue += radii[0];
            break;
          case 2:
            // If the corner is an even index, give it the first value, if odd, second value
            combinedValue += i % 2 ? radii[1] : radii[0];
            break;
          case 3:
            // The last corner should repeat the second value
            combinedValue += i === 3 ? radii[1] : radii[i];
            break;
          case 4:
          default:
            // Every value is specified
            combinedValue += radii[i];
        }
        values[i] = combinedValue;
      });
    });
  } else {
    values = value.split(/\s+/);
  }
  switch (values.length) {
    case 1:
      // If it's the same value all around, no need to use logical properties
      return "border-radius: ".concat(value, ";");
    case 2:
      borderRadiusMap['border-start-start-radius'] = values[0];
      borderRadiusMap['border-start-end-radius'] = values[1];
      borderRadiusMap['border-end-end-radius'] = values[0];
      borderRadiusMap['border-end-start-radius'] = values[1];
      break;
    case 3:
      borderRadiusMap['border-start-start-radius'] = values[0];
      borderRadiusMap['border-start-end-radius'] = values[1];
      borderRadiusMap['border-end-end-radius'] = values[2];
      borderRadiusMap['border-end-start-radius'] = values[1];
      break;
    case 4:
    default:
      borderRadiusMap['border-start-start-radius'] = values[0];
      borderRadiusMap['border-start-end-radius'] = values[1];
      borderRadiusMap['border-end-end-radius'] = values[2];
      borderRadiusMap['border-end-start-radius'] = values[3];
      break;
  }
  var borderRadiusCSS = [];
  Object.entries(borderRadiusMap).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      property = _ref2[0],
      value = _ref2[1];
    if (value) {
      if (ignoreZeroes && value !== '0' && value !== '0px' || !ignoreZeroes) {
        borderRadiusCSS.push("".concat(property, ": ").concat(value, ";"));
      }
    }
  });
  return borderRadiusCSS.join('\n');
};