function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["children", "className", "paddingSize", "borderRadius", "color", "hasShadow", "hasBorder", "grow", "panelRef", "element"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiBackgroundColorCSS, useEuiPaddingCSS, BACKGROUND_COLORS, PADDING_SIZES } from '../../global_styling';
import { keysOf } from '../common';
import { euiPanelStyles } from './panel.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SIZES = PADDING_SIZES;

// Exported padding sizes and class names necessary for EuiPopover and EuiCard.
// Which currently will only maintain support for the original values until conversion.
var paddingSizeToClassNameMap = {
  none: null,
  s: 'paddingSmall',
  m: 'paddingMedium',
  l: 'paddingLarge'
};
var _SIZES = keysOf(paddingSizeToClassNameMap);
export var BORDER_RADII = ['none', 'm'];
export var COLORS = BACKGROUND_COLORS;
export var EuiPanel = function EuiPanel(_ref) {
  var children = _ref.children,
    className = _ref.className,
    _ref$paddingSize = _ref.paddingSize,
    paddingSize = _ref$paddingSize === void 0 ? 'm' : _ref$paddingSize,
    _ref$borderRadius = _ref.borderRadius,
    borderRadius = _ref$borderRadius === void 0 ? 'm' : _ref$borderRadius,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? 'plain' : _ref$color,
    _ref$hasShadow = _ref.hasShadow,
    hasShadow = _ref$hasShadow === void 0 ? true : _ref$hasShadow,
    _ref$hasBorder = _ref.hasBorder,
    hasBorder = _ref$hasBorder === void 0 ? false : _ref$hasBorder,
    _ref$grow = _ref.grow,
    grow = _ref$grow === void 0 ? true : _ref$grow,
    panelRef = _ref.panelRef,
    element = _ref.element,
    rest = _objectWithoutProperties(_ref, _excluded);
  // Shadows are only allowed when there's a white background (plain)
  var canHaveShadow = !hasBorder && color === 'plain';
  var canHaveBorder = color === 'plain' || color === 'transparent';
  var styles = useEuiMemoizedStyles(euiPanelStyles);
  var cssStyles = [styles.euiPanel, grow && styles.grow, styles.radius[borderRadius], useEuiPaddingCSS()[paddingSize], useEuiBackgroundColorCSS()[color], canHaveShadow && hasShadow === true && styles.hasShadow, canHaveBorder && hasBorder === true && styles.hasBorder, rest.onClick && styles.isClickable];
  var classes = classNames('euiPanel', "euiPanel--".concat(color), _defineProperty({}, "euiPanel--".concat(paddingSizeToClassNameMap[paddingSize]), paddingSizeToClassNameMap[paddingSize]), className);
  if (rest.onClick && element !== 'div') {
    return ___EmotionJSX("button", _extends({
      ref: panelRef,
      className: classes,
      css: cssStyles
    }, rest), children);
  }
  return ___EmotionJSX("div", _extends({
    ref: panelRef,
    className: classes,
    css: cssStyles
  }, rest), children);
};
EuiPanel.propTypes = {
  element: PropTypes.oneOfType([PropTypes.oneOf(["button"]), PropTypes.oneOf(["div"])]),
  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  hasShadow: PropTypes.bool,
  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     */
  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     */
  hasBorder: PropTypes.bool,
  /**
     * Padding for all four sides
     */
  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,
  /**
     * Corner border radius
     */
  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,
  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,
  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};