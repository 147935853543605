var _excluded = ["onChange", "options", "value", "placeholder", "isPreFiltered", "listId", "className", "optionMatcher"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback } from 'react';
import classNames from 'classnames';
import { EuiFieldSearch } from '../../form';
import { getMatchingOptions } from '../matching_options';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiSelectableSearch = function EuiSelectableSearch(_ref) {
  var onChangeCallback = _ref.onChange,
    options = _ref.options,
    value = _ref.value,
    placeholder = _ref.placeholder,
    isPreFiltered = _ref.isPreFiltered,
    listId = _ref.listId,
    className = _ref.className,
    optionMatcher = _ref.optionMatcher,
    rest = _objectWithoutProperties(_ref, _excluded);
  var onChange = useCallback(function (e) {
    var searchValue = e.target.value;
    var matchingOptions = getMatchingOptions({
      options: options,
      searchValue: searchValue,
      isPreFiltered: isPreFiltered,
      optionMatcher: optionMatcher
    });
    onChangeCallback(searchValue, matchingOptions);
  }, [options, isPreFiltered, onChangeCallback, optionMatcher]);
  var classes = classNames('euiSelectableSearch', className);
  var ariaPropsIfListIsPresent = listId ? {
    role: 'combobox',
    'aria-autocomplete': 'list',
    'aria-expanded': true,
    'aria-controls': listId,
    'aria-owns': listId // legacy attribute but shims support for nearly everything atm
  } : undefined;
  return ___EmotionJSX(EuiFieldSearch, _extends({
    className: classes,
    placeholder: placeholder,
    value: value,
    onChange: onChange,
    incremental: true,
    fullWidth: true,
    autoComplete: "off",
    "aria-haspopup": "listbox"
  }, ariaPropsIfListIsPresent, rest));
};