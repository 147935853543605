function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["size", "className", "aria-label", "color", "style"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme, useEuiMemoizedStyles } from '../../services';
import { useLoadingAriaLabel } from './_loading_strings';
import { euiLoadingSpinnerStyles, euiSpinnerBorderColorsCSS } from './loading_spinner.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SIZES = ['s', 'm', 'l', 'xl', 'xxl'];
export var EuiLoadingSpinner = function EuiLoadingSpinner(_ref) {
  var _ref$size = _ref.size,
    size = _ref$size === void 0 ? 'm' : _ref$size,
    className = _ref.className,
    ariaLabel = _ref['aria-label'],
    color = _ref.color,
    style = _ref.style,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiLoadingSpinner', className);
  var styles = useEuiMemoizedStyles(euiLoadingSpinnerStyles);
  var cssStyles = [styles.euiLoadingSpinner, styles[size]];
  var euiTheme = useEuiTheme();
  var customColorStyle = color ? _objectSpread(_objectSpread({}, style), {}, {
    borderColor: euiSpinnerBorderColorsCSS(euiTheme, color)
  }) : style;
  var defaultLabel = useLoadingAriaLabel();
  return ___EmotionJSX("span", _extends({
    className: classes,
    css: cssStyles,
    style: customColorStyle,
    role: "progressbar",
    "aria-label": ariaLabel || defaultLabel
  }, rest));
};
EuiLoadingSpinner.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  size: PropTypes.any,
  /**
       * Sets the color of the border and highlight.
       * Each key accepts any valid CSS color value as a `string`
       * See #EuiLoadingSpinnerColor
       */
  color: PropTypes.shape({
    border: PropTypes.any,
    highlight: PropTypes.any
  })
};