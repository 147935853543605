/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

var getSearchableLabel = function getSearchableLabel(option) {
  var normalize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var searchableLabel = option.searchableLabel || option.label;
  return normalize ? searchableLabel.trim().toLowerCase() : searchableLabel;
};
var getSelectedOptionForSearchValue = function getSelectedOptionForSearchValue(searchValue, selectedOptions) {
  var normalizedSearchValue = searchValue.toLowerCase();
  return selectedOptions.find(function (option) {
    return getSearchableLabel(option) === normalizedSearchValue;
  });
};
var collectMatchingOption = function collectMatchingOption(_ref) {
  var selectedOptions = _ref.selectedOptions,
    isPreFiltered = _ref.isPreFiltered,
    option = _ref.option,
    accumulator = _ref.accumulator,
    searchValue = _ref.searchValue,
    normalizedSearchValue = _ref.normalizedSearchValue,
    optionMatcher = _ref.optionMatcher;
  // Don't show options that have already been requested if
  // the selectedOptions list exists
  if (selectedOptions) {
    var selectedOption = getSelectedOptionForSearchValue(getSearchableLabel(option, false), selectedOptions);
    if (selectedOption) {
      return false;
    }
  }

  // If the options have already been prefiltered then we can skip filtering against the search value.
  // TODO: I still don't quite understand how this works when hooked up to async
  if (isPreFiltered) {
    accumulator.push(option);
    return;
  }
  if (!normalizedSearchValue) {
    accumulator.push(option);
    return;
  }
  var isMatching = optionMatcher({
    option: option,
    searchValue: searchValue,
    normalizedSearchValue: normalizedSearchValue
  });
  if (isMatching) {
    accumulator.push(option);
  }
};
export var getMatchingOptions = function getMatchingOptions(_ref2) {
  var searchValue = _ref2.searchValue,
    options = _ref2.options,
    isPreFiltered = _ref2.isPreFiltered,
    _ref2$selectedOptions = _ref2.selectedOptions,
    selectedOptions = _ref2$selectedOptions === void 0 ? [] : _ref2$selectedOptions,
    optionMatcher = _ref2.optionMatcher;
  var normalizedSearchValue = searchValue.toLowerCase();
  var matchingOptions = [];
  options.forEach(function (option) {
    collectMatchingOption({
      accumulator: matchingOptions,
      option: option,
      searchValue: searchValue,
      normalizedSearchValue: normalizedSearchValue,
      isPreFiltered: isPreFiltered,
      selectedOptions: selectedOptions,
      optionMatcher: optionMatcher
    });
  });
  return matchingOptions;
};

/**
 * Partial string equality option matcher for EuiSelectable
 * It matches all options with labels including the searched string.
 */
export var createPartialStringEqualityOptionMatcher = function createPartialStringEqualityOptionMatcher() {
  return function (_ref3) {
    var option = _ref3.option,
      normalizedSearchValue = _ref3.normalizedSearchValue;
    var normalizedOption = getSearchableLabel(option);
    return normalizedOption.includes(normalizedSearchValue);
  };
};