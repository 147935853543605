function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { euiLineHeightFromBaseline, euiFontSizeFromScale } from '../functions/typography';
import { useEuiMemoizedStyles, useEuiTheme } from '../../services/theme';
import { EuiThemeFontScales } from '../variables/typography';
import { logicalCSS } from '../functions';
/**
 * Returns font-size and line-height
 */
export var euiFontSize = function euiFontSize(_ref, scale, options) {
  var euiTheme = _ref.euiTheme;
  return {
    fontSize: euiFontSizeFromScale(scale, euiTheme, options),
    lineHeight: euiLineHeightFromBaseline(scale, euiTheme, options)
  };
};
export var useEuiFontSize = function useEuiFontSize(scale, options) {
  var euiTheme = useEuiTheme();
  var memoizedFontSizes = useEuiMemoizedStyles(euiFontSizes);
  return !options ? memoizedFontSizes[scale] : euiFontSize(euiTheme, scale, options);
};
// Memomize a basic set of font sizes. We unfortunately can't
// memoize all possible options, there's too many permutations
var euiFontSizes = function euiFontSizes(euiThemeContext) {
  return EuiThemeFontScales.reduce(function (map, scale) {
    return _objectSpread(_objectSpread({}, map), {}, _defineProperty({}, scale, euiFontSize(euiThemeContext, scale)));
  }, {});
};

/**
 * Force text to wrap on natural word breaks (e.g. spaces & hyphens)
 * https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */
export var euiTextBreakWord = function euiTextBreakWord() {
  return "\n  overflow-wrap: break-word !important; // makes sure the long string will wrap and not bust out of the container\n  word-break: break-word;\n";
};

/**
 * Prevent text from wrapping onto multiple lines, and truncate with an ellipsis.
 */
export var euiTextTruncate = function euiTextTruncate() {
  var maxWidth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '100%';
  return "\n  ".concat(logicalCSS('max-width', maxWidth) // Ensure that the node has a maximum width after which truncation can occur
  , "\n  overflow: hidden !important;\n  text-overflow: ellipsis !important;\n  white-space: nowrap !important;\n");
};

/**
 * Fixed-width numbers for tabular data
 */
export var euiNumberFormat = function euiNumberFormat(_ref2) {
  var euiTheme = _ref2.euiTheme;
  return "\n  font-feature-settings: ".concat(euiTheme.font.featureSettings, ", 'tnum' 1;\n");
};