/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { useEuiTheme } from '../../../../services/theme';
import { getShadowColor } from '../functions';
/**
 * euiSlightShadow
 */
export var euiShadowXSmall = function euiShadowXSmall(_ref) {
  var euiTheme = _ref.euiTheme,
    colorMode = _ref.colorMode;
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _color = _ref2.color;
  var color = _color || euiTheme.colors.shadow;
  return "\nbox-shadow:\n  0 .8px .8px ".concat(getShadowColor(color, 0.04, colorMode), ",\n  0 2.3px 2px ").concat(getShadowColor(color, 0.03, colorMode), ";\n");
};

/**
 * bottomShadowSmall
 */
export var euiShadowSmall = function euiShadowSmall(_ref3) {
  var euiTheme = _ref3.euiTheme,
    colorMode = _ref3.colorMode;
  var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _color = _ref4.color;
  var color = _color || euiTheme.colors.shadow;
  return "\nbox-shadow:\n  0 .7px 1.4px ".concat(getShadowColor(color, 0.07, colorMode), ",\n  0 1.9px 4px ").concat(getShadowColor(color, 0.05, colorMode), ",\n  0 4.5px 10px ").concat(getShadowColor(color, 0.05, colorMode), ";\n");
};

/**
 * bottomShadowMedium
 */
export var euiShadowMedium = function euiShadowMedium(_ref5) {
  var euiTheme = _ref5.euiTheme,
    colorMode = _ref5.colorMode;
  var _ref6 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _color = _ref6.color,
    property = _ref6.property;
  var color = _color || euiTheme.colors.shadow;
  if (property === 'filter') {
    // Using only one drop-shadow filter instead of multiple is more performant & prevents Safari bugs
    return "filter: drop-shadow(0 5.7px 9px ".concat(getShadowColor(color, 0.2, colorMode), ");");
  } else {
    return "box-shadow:\n      0 .9px 4px ".concat(getShadowColor(color, 0.08, colorMode), ",\n      0 2.6px 8px ").concat(getShadowColor(color, 0.06, colorMode), ",\n      0 5.7px 12px ").concat(getShadowColor(color, 0.05, colorMode), ",\n      0 15px 15px ").concat(getShadowColor(color, 0.04, colorMode), ";");
  }
};

/**
 * bottomShadow
 */
export var euiShadowLarge = function euiShadowLarge(_ref7) {
  var euiTheme = _ref7.euiTheme,
    colorMode = _ref7.colorMode;
  var _ref8 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _color = _ref8.color;
  var color = _color || euiTheme.colors.shadow;
  return "\nbox-shadow:\n  0 1px 5px ".concat(getShadowColor(color, 0.1, colorMode), ",\n  0 3.6px 13px ").concat(getShadowColor(color, 0.07, colorMode), ",\n  0 8.4px 23px ").concat(getShadowColor(color, 0.06, colorMode), ",\n  0 23px 35px ").concat(getShadowColor(color, 0.05, colorMode), ";\n");
};

/**
 * bottomShadowLarge
 */

export var euiShadowXLarge = function euiShadowXLarge(_ref9) {
  var euiTheme = _ref9.euiTheme,
    colorMode = _ref9.colorMode;
  var _ref10 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _color = _ref10.color,
    reverse = _ref10.reverse;
  var color = _color || euiTheme.colors.shadow;
  return "\nbox-shadow:\n  0 ".concat(reverse ? '-' : '', "2.7px 9px ").concat(getShadowColor(color, 0.13, colorMode), ",\n  0 ").concat(reverse ? '-' : '', "9.4px 24px ").concat(getShadowColor(color, 0.09, colorMode), ",\n  0 ").concat(reverse ? '-' : '', "21.8px 43px ").concat(getShadowColor(color, 0.08, colorMode), ";\n");
};

/**
 * slightShadowHover
 */
export var euiSlightShadowHover = function euiSlightShadowHover(_ref11) {
  var euiTheme = _ref11.euiTheme,
    colorMode = _ref11.colorMode;
  var _ref12 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _color = _ref12.color;
  var color = _color || euiTheme.colors.shadow;
  return "\nbox-shadow:\n  0 1px 5px ".concat(getShadowColor(color, 0.1, colorMode), ",\n  0 3.6px 13px ").concat(getShadowColor(color, 0.07, colorMode), ",\n  0 8.4px 23px ").concat(getShadowColor(color, 0.06, colorMode), ",\n  0 23px 35px ").concat(getShadowColor(color, 0.05, colorMode), ";\n");
};
export var useEuiSlightShadowHover = function useEuiSlightShadowHover(color) {
  var euiThemeContext = useEuiTheme();
  return euiSlightShadowHover(euiThemeContext, {
    color: color
  });
};

/**
 * bottomShadowFlat
 *
 * Similar to shadow medium but without the bottom depth.
 * Useful for popovers that drop UP rather than DOWN.
 */
export var euiShadowFlat = function euiShadowFlat(_ref13) {
  var euiTheme = _ref13.euiTheme,
    colorMode = _ref13.colorMode;
  var _ref14 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _color = _ref14.color;
  var color = _color || euiTheme.colors.shadow;
  return "\nbox-shadow:\n  0 0 .8px ".concat(getShadowColor(color, 0.06, colorMode), ",\n  0 0 2px ").concat(getShadowColor(color, 0.04, colorMode), ",\n  0 0 5px ").concat(getShadowColor(color, 0.04, colorMode), ",\n  0 0 17px ").concat(getShadowColor(color, 0.03, colorMode), ";\n");
};
export var useEuiShadowFlat = function useEuiShadowFlat(color) {
  var euiThemeContext = useEuiTheme();
  return euiShadowFlat(euiThemeContext, {
    color: color
  });
};
export var euiShadow = function euiShadow(euiThemeContext) {
  var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'l';
  var _ref15 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    color = _ref15.color;
  switch (size) {
    case 'xs':
      return euiShadowXSmall(euiThemeContext, {
        color: color
      });
    case 's':
      return euiShadowSmall(euiThemeContext, {
        color: color
      });
    case 'm':
      return euiShadowMedium(euiThemeContext, {
        color: color
      });
    case 'l':
      return euiShadowLarge(euiThemeContext, {
        color: color
      });
    case 'xl':
      return euiShadowXLarge(euiThemeContext, {
        color: color
      });
    default:
      console.warn('Please provide a valid size option to useEuiShadow');
      return '';
  }
};
export var useEuiShadow = function useEuiShadow() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'l';
  var color = arguments.length > 1 ? arguments[1] : undefined;
  var euiThemeContext = useEuiTheme();
  return euiShadow(euiThemeContext, size, {
    color: color
  });
};