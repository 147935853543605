var _excluded = ["children", "className", "hasScreenReaderHelpText"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiI18n } from '../i18n';
import { useEuiTheme } from '../../services';
import { euiMarkStyles, euiMarkScreenReaderStyles } from './mark.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiMark = function EuiMark(_ref) {
  var children = _ref.children,
    className = _ref.className,
    _ref$hasScreenReaderH = _ref.hasScreenReaderHelpText,
    hasScreenReaderHelpText = _ref$hasScreenReaderH === void 0 ? true : _ref$hasScreenReaderH,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiMark', className);
  var euiTheme = useEuiTheme();
  var styles = euiMarkStyles(euiTheme);
  var highlightStart = useEuiI18n('euiMark.highlightStart', 'highlight start');
  var highlightEnd = useEuiI18n('euiMark.highlightEnd', 'highlight end');
  var screenReaderStyles = useMemo(function () {
    return euiMarkScreenReaderStyles(highlightStart, highlightEnd);
  }, [highlightStart, highlightEnd]);
  var cssStyles = [styles.euiMark, hasScreenReaderHelpText && screenReaderStyles.hasScreenReaderHelpText];
  return ___EmotionJSX("mark", _extends({
    css: cssStyles,
    className: classes
  }, rest), children);
};
EuiMark.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Set to `false` to remove the CSS :before and :after
       * screen reader helper text
       */
  hasScreenReaderHelpText: PropTypes.bool,
  /**
       * ReactNode to render as this component's content
       */
  children: PropTypes.node.isRequired
};