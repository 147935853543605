var _excluded = ["children", "alignment", "restrictWidth", "bottomBorder", "paddingSize", "color", "grow", "contentProps", "component"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { setStyleForRestrictedPageWidth } from '../_restrict_width';
import { useEuiTheme } from '../../../services';
import { euiPageSectionContentStyles, euiPageSectionStyles } from './page_section.styles';
import { useEuiPaddingCSS, useEuiBackgroundColorCSS } from '../../../global_styling';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiPageSection = function EuiPageSection(_ref) {
  var children = _ref.children,
    _ref$alignment = _ref.alignment,
    alignment = _ref$alignment === void 0 ? 'top' : _ref$alignment,
    _ref$restrictWidth = _ref.restrictWidth,
    restrictWidth = _ref$restrictWidth === void 0 ? false : _ref$restrictWidth,
    bottomBorder = _ref.bottomBorder,
    _ref$paddingSize = _ref.paddingSize,
    paddingSize = _ref$paddingSize === void 0 ? 'l' : _ref$paddingSize,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? 'transparent' : _ref$color,
    _ref$grow = _ref.grow,
    grow = _ref$grow === void 0 ? false : _ref$grow,
    contentProps = _ref.contentProps,
    _ref$component = _ref.component,
    Component = _ref$component === void 0 ? 'section' : _ref$component,
    rest = _objectWithoutProperties(_ref, _excluded);
  // Set max-width as a style prop
  var widthStyles = setStyleForRestrictedPageWidth(restrictWidth, contentProps === null || contentProps === void 0 ? void 0 : contentProps.style);
  var useTheme = useEuiTheme();
  var styles = euiPageSectionStyles(useTheme);
  var inlinePadding = useEuiPaddingCSS('horizontal');
  var blockPadding = useEuiPaddingCSS('vertical');
  var colors = useEuiBackgroundColorCSS();
  var cssStyles = [styles.euiPageSection, grow && styles.grow, inlinePadding[paddingSize], bottomBorder === 'extended' && styles.border, alignment && styles[alignment], colors[color]];
  var contentStyles = euiPageSectionContentStyles();
  var cssContentStyles = [contentStyles.euiPageSection__content, blockPadding[paddingSize], bottomBorder === true && styles.border, alignment.toLowerCase().includes('center') && contentStyles.center, restrictWidth && contentStyles.restrictWidth, contentProps === null || contentProps === void 0 ? void 0 : contentProps.css];
  return ___EmotionJSX(Component, _extends({
    css: cssStyles
  }, rest), ___EmotionJSX("div", _extends({}, contentProps, {
    css: cssContentStyles,
    style: widthStyles
  }), children));
};
EuiPageSection.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Adds a bottom border to separate it from the content after;
     * Passing `extended` will ensure the border touches the sides of the parent container.
     */
  bottomBorder: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["extended"])]),
  /**
       * Background color of the section;
       * Usually a lightened form of the brand colors
       */
  color: PropTypes.any,
  /**
       * Padding for all four sides
       */
  paddingSize: PropTypes.any,
  /**
       * Horizontal and/or vertical alignment of the section contents
       */
  alignment: PropTypes.any,
  /**
       * When true the panel will grow in height to fill container if parent is a flex group
       */
  grow: PropTypes.bool,
  /**
       * Passed down to the div wrapper of the section contents
       */
  contentProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
       * Sets which HTML element to render.
       */
  component: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.elementType.isRequired])
};