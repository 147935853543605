/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import { CacheProvider } from '@emotion/react';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiCacheProvider = function EuiCacheProvider(_ref) {
  var cache = _ref.cache,
    children = _ref.children;
  return children && cache ? ___EmotionJSX(CacheProvider, {
    value: cache
  }, children) : ___EmotionJSX(React.Fragment, null, children);
};