var _excluded = ["children", "className", "gutterSize", "fullWidth", "ratio", "titleSize", "title", "description", "descriptionFlexItemProps", "fieldFlexItemProps"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiTitle } from '../../title';
import { EuiText } from '../../text';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
import { useFormContext } from '../eui_form_context';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiDescribedFormGroup = function EuiDescribedFormGroup(props) {
  var _useFormContext = useFormContext(),
    defaultFullWidth = _useFormContext.defaultFullWidth;
  var children = props.children,
    className = props.className,
    _props$gutterSize = props.gutterSize,
    gutterSize = _props$gutterSize === void 0 ? 'l' : _props$gutterSize,
    _props$fullWidth = props.fullWidth,
    fullWidth = _props$fullWidth === void 0 ? defaultFullWidth : _props$fullWidth,
    _props$ratio = props.ratio,
    ratio = _props$ratio === void 0 ? 'half' : _props$ratio,
    _props$titleSize = props.titleSize,
    titleSize = _props$titleSize === void 0 ? 'xs' : _props$titleSize,
    title = props.title,
    description = props.description,
    descriptionFlexItemProps = props.descriptionFlexItemProps,
    fieldFlexItemProps = props.fieldFlexItemProps,
    rest = _objectWithoutProperties(props, _excluded);
  var classes = classNames('euiDescribedFormGroup', {
    'euiDescribedFormGroup--fullWidth': fullWidth
  }, className);
  var fieldClasses = classNames('euiDescribedFormGroup__fields', fieldFlexItemProps && fieldFlexItemProps.className);
  var renderedDescription;
  if (description) {
    renderedDescription = ___EmotionJSX(EuiText, {
      size: "s",
      color: "subdued",
      className: "euiDescribedFormGroup__description"
    },
    // If the description is just a string, wrap it in a paragraph element
    typeof description === 'string' ? ___EmotionJSX("p", null, description) : description);
  }
  var fieldGrowth;
  switch (ratio) {
    case 'half':
      fieldGrowth = 1;
      break;
    case 'third':
      fieldGrowth = 2;
      break;
    case 'quarter':
      fieldGrowth = 3;
      break;
    default:
      console.warn('Please provide an allowed ratio to EuiDescribedFromRow');
      break;
  }
  return ___EmotionJSX("div", _extends({
    role: "group",
    className: classes
  }, rest), ___EmotionJSX(EuiFlexGroup, {
    alignItems: "baseline",
    gutterSize: gutterSize
  }, ___EmotionJSX(EuiFlexItem, _extends({
    grow: 1
  }, descriptionFlexItemProps, {
    className: classNames('euiDescribedFormGroup__descriptionColumn', descriptionFlexItemProps === null || descriptionFlexItemProps === void 0 ? void 0 : descriptionFlexItemProps.className)
  }), ___EmotionJSX(EuiTitle, {
    size: titleSize,
    className: "euiDescribedFormGroup__title"
  }, title), renderedDescription), ___EmotionJSX(EuiFlexItem, _extends({
    grow: fieldGrowth
  }, fieldFlexItemProps, {
    className: fieldClasses
  }), children)));
};
EuiDescribedFormGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * One or more `EuiFormRow`s.
       */
  children: PropTypes.node,
  /**
       * Passed to `EuiFlexGroup`.
       * @default l
       */
  gutterSize: PropTypes.any,
  /**
       * Expand to fill 100% of the parent.
       * Defaults to `fullWidth` prop of `<EuiForm>`.
       * Default max-width is 800px.
       * @default false
       */
  fullWidth: PropTypes.bool,
  /**
       * Width ratio of description column compared to field column.
       * Can be used in conjunction with `fullWidth` and
       * may require `fullWidth` to be applied to child elements.
       * @default half
       */
  ratio: PropTypes.oneOf(["half", "third", "quarter"]),
  /**
       * For better accessibility, it's recommended to use an HTML heading.
       */
  title: PropTypes.element.isRequired,
  /**
       * Adjust the visual `size` of the EuiTitle that wraps `title`.
       * @default xs
       */
  titleSize: PropTypes.any,
  /**
       * Added as a child of `EuiText`.
       */
  description: PropTypes.node,
  /**
       * For customizing the field container. Extended from `EuiFlexItem`.
       */
  descriptionFlexItemProps: PropTypes.any,
  fieldFlexItemProps: PropTypes.any
};