function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { shade, tint, darken, transparentize, makeHighContrastColor } from '../../services';
import { logicalCSS, mathWithUnits, euiCanAnimate, euiFontSize } from '../../global_styling';
import { euiButtonColor } from '../../themes/amsterdam/global_styling/mixins';

// There are multiple components that only need the form max-width size &
// don't need the extra overhead/color computing expense of every form var.
// For microperf, we're making this its own util
export var euiFormMaxWidth = function euiFormMaxWidth(_ref) {
  var euiTheme = _ref.euiTheme;
  return mathWithUnits(euiTheme.size.base, function (x) {
    return x * 25;
  });
};
export var euiFormVariables = function euiFormVariables(euiThemeContext) {
  var euiTheme = euiThemeContext.euiTheme,
    colorMode = euiThemeContext.colorMode;
  var isColorDark = colorMode === 'DARK';
  var backgroundColor = isColorDark ? shade(euiTheme.colors.lightestShade, 0.4) : tint(euiTheme.colors.lightestShade, 0.6);
  var controlHeight = euiTheme.size.xxl;
  var controlCompressedHeight = euiTheme.size.xl;
  var sizes = {
    maxWidth: euiFormMaxWidth(euiThemeContext),
    controlHeight: controlHeight,
    controlCompressedHeight: controlCompressedHeight,
    controlPadding: euiTheme.size.m,
    controlCompressedPadding: euiTheme.size.s,
    controlBorderRadius: euiTheme.border.radius.medium,
    controlCompressedBorderRadius: euiTheme.border.radius.small,
    iconAffordance: mathWithUnits(euiTheme.size.base, function (x) {
      return x * 1.5;
    }),
    iconCompressedAffordance: mathWithUnits(euiTheme.size.m, function (x) {
      return x * 1.5;
    })
  };
  var colors = {
    textColor: euiTheme.colors.text,
    backgroundColor: backgroundColor,
    backgroundDisabledColor: darken(euiTheme.colors.lightestShade, 0.05),
    backgroundReadOnlyColor: euiTheme.colors.emptyShade,
    borderColor: transparentize(colorMode === 'DARK' ? euiTheme.colors.ghost : darken(euiTheme.border.color, 4), 0.1),
    controlDisabledColor: euiTheme.colors.mediumShade,
    controlBoxShadow: '0 0 transparent',
    controlPlaceholderText: makeHighContrastColor(euiTheme.colors.subduedText)(backgroundColor),
    inputGroupLabelBackground: isColorDark ? shade(euiTheme.colors.lightShade, 0.15) : tint(euiTheme.colors.lightShade, 0.5),
    inputGroupBorder: 'none'
  };

  // Colors - specific to checkboxes, radios, switches, and range thumbs
  var customControlColors = {
    customControlDisabledIconColor: isColorDark ? shade(euiTheme.colors.mediumShade, 0.38) : tint(euiTheme.colors.mediumShade, 0.485),
    customControlBorderColor: isColorDark ? shade(euiTheme.colors.lightestShade, 0.4) : tint(euiTheme.colors.lightestShade, 0.31)
  };
  var controlLayout = {
    controlLayoutGroupInputHeight: mathWithUnits(controlHeight, function (x) {
      return x - 2;
    }),
    controlLayoutGroupInputCompressedHeight: mathWithUnits(controlCompressedHeight, function (x) {
      return x - 2;
    }),
    controlLayoutGroupInputCompressedBorderRadius: euiTheme.border.radius.small
  };
  var iconSizes = {
    controlIconSize: {
      s: euiTheme.size.m,
      m: euiTheme.size.base,
      l: euiTheme.size.l,
      xl: euiTheme.size.xl,
      xxl: euiTheme.size.xxl
    }
  };
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, sizes), colors), customControlColors), iconSizes), controlLayout), {}, {
    animationTiming: "".concat(euiTheme.animation.fast, " ease-in")
  });
};
export var euiFormControlStyles = function euiFormControlStyles(euiThemeContext) {
  var form = euiFormVariables(euiThemeContext);
  return {
    shared: "\n      ".concat(euiFormControlText(euiThemeContext), "\n      ").concat(euiFormControlDefaultShadow(euiThemeContext), "\n    "),
    // Sizes
    uncompressed: "\n      ".concat(logicalCSS('height', form.controlHeight), "\n      ").concat(logicalCSS('padding-vertical', form.controlPadding), "\n      ").concat(logicalCSS('padding-left', "calc(".concat(form.controlPadding, " + (").concat(form.iconAffordance, " * var(--euiFormControlLeftIconsCount, 0)))")), "\n      ").concat(logicalCSS('padding-right', "calc(".concat(form.controlPadding, " + (").concat(form.iconAffordance, " * var(--euiFormControlRightIconsCount, 0)))")), "\n      border-radius: ").concat(form.controlBorderRadius, ";\n    "),
    compressed: "\n      ".concat(logicalCSS('height', form.controlCompressedHeight), "\n      ").concat(logicalCSS('padding-vertical', form.controlCompressedPadding), "\n      ").concat(logicalCSS('padding-left', "calc(".concat(form.controlCompressedPadding, " + (").concat(form.iconCompressedAffordance, " * var(--euiFormControlLeftIconsCount, 0)))")), "\n      ").concat(logicalCSS('padding-right', "calc(".concat(form.controlCompressedPadding, " + (").concat(form.iconCompressedAffordance, " * var(--euiFormControlRightIconsCount, 0)))")), "\n      border-radius: ").concat(form.controlCompressedBorderRadius, ";\n    "),
    // In group
    inGroup: "\n      ".concat(logicalCSS('height', '100%'), "\n      box-shadow: none;\n      border-radius: 0;\n    "),
    // Widths
    formWidth: "\n      ".concat(logicalCSS('max-width', form.maxWidth), "\n      ").concat(logicalCSS('width', '100%'), "\n    "),
    fullWidth: "\n      ".concat(logicalCSS('max-width', '100%'), "\n      ").concat(logicalCSS('width', '100%'), "\n    "),
    // States
    invalid: euiFormControlInvalidStyles(euiThemeContext),
    focus: euiFormControlFocusStyles(euiThemeContext),
    disabled: euiFormControlDisabledStyles(euiThemeContext),
    readOnly: euiFormControlReadOnlyStyles(euiThemeContext),
    autoFill: euiFormControlAutoFillStyles(euiThemeContext)
  };
};
export var euiCustomControl = function euiCustomControl(euiThemeContext) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var euiTheme = euiThemeContext.euiTheme;
  var form = euiFormVariables(euiThemeContext);
  var type = options.type,
    _options$size = options.size,
    size = _options$size === void 0 ? euiTheme.size.base : _options$size;
  var padddingStyle = '';
  var borderRadiusStyle = '';
  if (size) {
    var borderSize = parseFloat(String(euiTheme.border.width.thin));
    var paddingSize = mathWithUnits(size, function (x) {
      return (x - borderSize * 2) / 2;
    });
    padddingStyle = "padding: ".concat(paddingSize, ";");
  }
  if (type === 'round') {
    borderRadiusStyle = "border-radius: ".concat(size, ";");
  } else if (type === 'square') {
    borderRadiusStyle = "border-radius: ".concat(form.controlCompressedBorderRadius, ";");
  }
  return "\n    ".concat(padddingStyle, "\n    ").concat(borderRadiusStyle, "\n    border: ").concat(euiTheme.border.width.thin, " solid ").concat(form.customControlBorderColor, ";\n    background: ").concat(euiTheme.colors.emptyShade, " no-repeat center;\n\n    ").concat(euiCanAnimate, " {\n      transition: background-color ").concat(form.animationTiming, ",\n        border-color ").concat(form.animationTiming, ";\n    }\n  ");
};
export var euiFormControlText = function euiFormControlText(euiThemeContext) {
  var euiTheme = euiThemeContext.euiTheme;
  var _euiFontSize = euiFontSize(euiThemeContext, 's'),
    fontSize = _euiFontSize.fontSize;
  var form = euiFormVariables(euiThemeContext);
  return "\n    font-family: ".concat(euiTheme.font.family, ";\n    font-size: ").concat(fontSize, ";\n    color: ").concat(form.textColor, ";\n\n    ").concat(euiPlaceholderPerBrowser("\n      color: ".concat(form.controlPlaceholderText, ";\n      opacity: 1;\n    ")), "\n  ");
};
export var euiFormControlDefaultShadow = function euiFormControlDefaultShadow(euiThemeContext) {
  var euiTheme = euiThemeContext.euiTheme;
  var form = euiFormVariables(euiThemeContext);
  return "\n    /* We use inset box-shadow instead of border to skip extra hight calculations */\n    border: none;\n    box-shadow: inset 0 0 0 ".concat(euiTheme.border.width.thin, " ").concat(form.borderColor, ";\n    background-color: ").concat(form.backgroundColor, ";\n\n    background-repeat: no-repeat;\n    background-size: 0% 100%;\n    background-image: linear-gradient(to top,\n      var(--euiFormControlStateColor),\n      var(--euiFormControlStateColor) ").concat(euiTheme.border.width.thick, ",\n      transparent ").concat(euiTheme.border.width.thick, ",\n      transparent 100%\n    );\n\n    ").concat(euiCanAnimate, " {\n      transition:\n        box-shadow ").concat(form.animationTiming, ",\n        background-image ").concat(form.animationTiming, ",\n        background-size ").concat(form.animationTiming, ",\n        background-color ").concat(form.animationTiming, ";\n    }\n  ");
};
export var euiFormControlFocusStyles = function euiFormControlFocusStyles(_ref2) {
  var euiTheme = _ref2.euiTheme,
    colorMode = _ref2.colorMode;
  return "\n  --euiFormControlStateColor: ".concat(euiTheme.colors.primary, ";\n  background-color: ").concat(colorMode === 'DARK' ? shade(euiTheme.colors.emptyShade, 0.4) : euiTheme.colors.emptyShade, ";\n  background-size: 100% 100%;\n  outline: none; /* Remove all outlines and rely on our own bottom border gradient */\n");
};
export var euiFormControlInvalidStyles = function euiFormControlInvalidStyles(_ref3) {
  var euiTheme = _ref3.euiTheme;
  return "\n  --euiFormControlStateColor: ".concat(euiTheme.colors.danger, ";\n  background-size: 100% 100%;\n");
};
export var euiFormControlDisabledStyles = function euiFormControlDisabledStyles(euiThemeContext) {
  var form = euiFormVariables(euiThemeContext);
  return "\n    color: ".concat(form.controlDisabledColor, ";\n    /* Required for Safari */\n    -webkit-text-fill-color: ").concat(form.controlDisabledColor, ";\n    background-color: ").concat(form.backgroundDisabledColor, ";\n    cursor: not-allowed;\n\n    ").concat(euiPlaceholderPerBrowser("\n      color: ".concat(form.controlDisabledColor, ";\n      opacity: 1;\n    ")), "\n  ");
};
export var euiFormControlReadOnlyStyles = function euiFormControlReadOnlyStyles(euiThemeContext) {
  var form = euiFormVariables(euiThemeContext);
  return "\n    cursor: default;\n    color: ".concat(form.textColor, ";\n    -webkit-text-fill-color: ").concat(form.textColor, "; /* Required for Safari */\n\n    background-color: ").concat(form.backgroundReadOnlyColor, ";\n    --euiFormControlStateColor: transparent;\n  ");
};
export var euiFormControlAutoFillStyles = function euiFormControlAutoFillStyles(euiThemeContext) {
  var euiTheme = euiThemeContext.euiTheme,
    colorMode = euiThemeContext.colorMode;

  // Make the text color slightly less prominent than the default colors.text
  var textColor = euiTheme.colors.darkestShade;
  var _euiButtonColor = euiButtonColor(euiThemeContext, 'primary'),
    backgroundColor = _euiButtonColor.backgroundColor;
  var tintedBackgroundColor = colorMode === 'DARK' ? shade(backgroundColor, 0.5) : tint(backgroundColor, 0.7);
  // Hacky workaround to background-color, since Chrome doesn't normally allow overriding its styles
  // @see https://developer.mozilla.org/en-US/docs/Web/CSS/:autofill#sect1
  var backgroundShadow = "inset 0 0 0 100vw ".concat(tintedBackgroundColor);

  // Re-create the border, since the above webkit box shadow overrides the default border box-shadow
  // + change the border color to match states, since the underline background gradient no longer works
  var borderColor = transparentize(euiTheme.colors.primaryText, 0.2);
  var invalidBorder = euiTheme.colors.danger;
  var borderShadow = function borderShadow(color) {
    return "inset 0 0 0 ".concat(euiTheme.border.width.thin, " ").concat(color);
  };

  // These styles only apply/override Chrome/webkit browsers - Firefox does not set autofill styles
  return "\n    &:-webkit-autofill {\n      -webkit-text-fill-color: ".concat(textColor, ";\n      -webkit-box-shadow: ").concat(borderShadow(borderColor), ", ").concat(backgroundShadow, ";\n\n      &:invalid {\n        -webkit-box-shadow: ").concat(borderShadow(invalidBorder), ", ").concat(backgroundShadow, ";\n      }\n    }\n  ");
};
var euiPlaceholderPerBrowser = function euiPlaceholderPerBrowser(content) {
  return "\n  &::-webkit-input-placeholder { ".concat(content, " }\n  &::-moz-placeholder { ").concat(content, " }\n  &:-ms-input-placeholder { ").concat(content, " }\n  &:-moz-placeholder { ").concat(content, " }\n  &::placeholder { ").concat(content, " }\n");
};