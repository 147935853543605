function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

/**
 * The `restrictedWidth` property is the same for all EuiPage components.
 * This is file contains the type specific to that prop and a helper
 * function for creating the corresponding classNames and style tags
 * based on the consumer's configuration
 */

// TODO: Decide how to make this a global value but still isolated to the page component
export var PAGE_MAX_WIDTH = '1200px';
/**
 * **DEPRECATED**
 * This function calculates the correct class name and combined styles
 * based on the `restrictWidth` value passed in
 *
 * @param restrictWidth `boolean | number | string` The prop value
 * @param style `CSSProperties` An object of style attributes if provided
 * @returns An object with keys for the `widthClassName` to append to the component's class and the updated `newStyle` props
 */
export function setPropsForRestrictedPageWidth(restrictWidth, style) {
  var widthClassName;
  var newStyle = _objectSpread({}, style);
  if (restrictWidth === true) {
    widthClassName = 'restrictWidth-default';
  } else if (restrictWidth !== false) {
    widthClassName = 'restrictWidth-custom';
    newStyle.maxWidth = restrictWidth;
  }
  return {
    widthClassName: widthClassName,
    newStyle: newStyle
  };
}

/**
 * This function calculates the correct just the combined styles
 * based on the `restrictWidth` value passed in
 *
 * @param restrictWidth `boolean | number | string` The prop value
 * @param style `CSSProperties` An object of style attributes if provided
 * @returns An object of the updated `style` props
 */
export function setStyleForRestrictedPageWidth(restrictWidth, style) {
  var newStyle = _objectSpread({}, style);
  if (restrictWidth === true) {
    newStyle.maxWidth = PAGE_MAX_WIDTH;
  } else if (restrictWidth !== false) {
    newStyle.maxWidth = restrictWidth;
  }
  return newStyle;
}