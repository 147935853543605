var _excluded = ["children", "className", "search", "strict", "highlightAll", "hasScreenReaderHelpText"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import { EuiMark } from '../mark';
import { HighlightAll } from './_highlight_all';
import { HighlightFirst } from './_highlight_first';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiHighlight = function EuiHighlight(_ref) {
  var children = _ref.children,
    className = _ref.className,
    search = _ref.search,
    _ref$strict = _ref.strict,
    strict = _ref$strict === void 0 ? false : _ref$strict,
    _ref$highlightAll = _ref.highlightAll,
    highlightAll = _ref$highlightAll === void 0 ? false : _ref$highlightAll,
    _ref$hasScreenReaderH = _ref.hasScreenReaderHelpText,
    hasScreenReaderHelpText = _ref$hasScreenReaderH === void 0 ? true : _ref$hasScreenReaderH,
    rest = _objectWithoutProperties(_ref, _excluded);
  var hasSearch = search && search.length > 0;
  var HighlightComponent = useMemo(function () {
    var Component = function Component(_ref2) {
      var children = _ref2.children;
      return ___EmotionJSX(EuiMark, {
        hasScreenReaderHelpText: hasScreenReaderHelpText
      }, children);
    };
    Component.propTypes = {
      children: PropTypes.string.isRequired
    };
    Component.displayName = '_HighlightComponent';
    return Component;
  }, [hasScreenReaderHelpText]);
  return ___EmotionJSX("span", _extends({
    className: className
  }, rest), children && hasSearch ? highlightAll ? ___EmotionJSX(HighlightAll, {
    searchValue: search,
    searchSubject: children,
    isStrict: strict,
    highlightComponent: HighlightComponent
  }) : ___EmotionJSX(HighlightFirst, {
    searchValue: search,
    searchSubject: children,
    isStrict: strict,
    highlightComponent: HighlightComponent
  }) : children);
};
EuiHighlight.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * string to highlight as this component's content
       */
  children: PropTypes.string.isRequired,
  /**
       * What to search for.
       *
       * Allows passing an array of strings (searching by multiple separate
       * words or phrases) **only** if `highlightAll` is also set to `true`.
       */
  search: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]).isRequired,
  /**
       * Should the search be strict or not
       */
  strict: PropTypes.bool,
  /**
       * Should highlight all matches
       */
  highlightAll: PropTypes.bool
};