function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { shade, tint } from '../../../../services/color';
import { computed } from '../../../../services/theme/utils';
import { makeHighContrastColor, makeDisabledContrastColor } from '../../../../services/color/contrast';
/*
 * LIGHT THEME
 * Only split up in the light theme to access the keys by section in the docs
 */

export var brand_colors = {
  primary: '#07C',
  accent: '#F04E98',
  success: '#00BFB3',
  warning: '#FEC514',
  danger: '#BD271E'
};
export var brand_text_colors = {
  primaryText: computed(makeHighContrastColor('colors.primary')),
  accentText: computed(makeHighContrastColor('colors.accent')),
  successText: computed(makeHighContrastColor('colors.success')),
  warningText: computed(makeHighContrastColor('colors.warning')),
  dangerText: computed(makeHighContrastColor('colors.danger'))
};
export var shade_colors = {
  emptyShade: '#FFF',
  lightestShade: '#F1F4FA',
  lightShade: '#D3DAE6',
  mediumShade: '#98A2B3',
  darkShade: '#69707D',
  darkestShade: '#343741',
  fullShade: '#000'
};
export var special_colors = {
  body: computed(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      lightestShade = _ref2[0];
    return tint(lightestShade, 0.4);
  }, ['colors.lightestShade']),
  highlight: computed(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 1),
      warning = _ref4[0];
    return tint(warning, 0.9);
  }, ['colors.warning']),
  disabled: '#ABB4C4',
  disabledText: computed(makeDisabledContrastColor('colors.disabled')),
  shadow: computed(function (_ref5) {
    var colors = _ref5.colors;
    return colors.ink;
  })
};
export var text_colors = {
  text: computed(function (_ref6) {
    var _ref7 = _slicedToArray(_ref6, 1),
      darkestShade = _ref7[0];
    return darkestShade;
  }, ['colors.darkestShade']),
  title: computed(function (_ref8) {
    var _ref9 = _slicedToArray(_ref8, 1),
      text = _ref9[0];
    return shade(text, 0.5);
  }, ['colors.text']),
  subduedText: computed(makeHighContrastColor('colors.darkShade')),
  link: computed(function (_ref10) {
    var _ref11 = _slicedToArray(_ref10, 1),
      primaryText = _ref11[0];
    return primaryText;
  }, ['colors.primaryText'])
};
export var light_colors = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, brand_colors), shade_colors), special_colors), brand_text_colors), text_colors);

/*
 * DARK THEME
 */

export var dark_shades = {
  emptyShade: '#1D1E24',
  lightestShade: '#25262E',
  lightShade: '#343741',
  mediumShade: '#535966',
  darkShade: '#98A2B3',
  darkestShade: '#D4DAE5',
  fullShade: '#FFF'
};
export var dark_colors_ams = _objectSpread(_objectSpread(_objectSpread({
  // Brand
  primary: '#36A2EF',
  accent: '#F68FBE',
  success: '#7DDED8',
  warning: '#F3D371',
  danger: '#F86B63'
}, dark_shades), {}, {
  // Special
  body: computed(function (_ref12) {
    var _ref13 = _slicedToArray(_ref12, 1),
      lightestShade = _ref13[0];
    return shade(lightestShade, 0.45);
  }, ['colors.lightestShade']),
  highlight: '#2E2D25',
  disabled: '#515761',
  disabledText: computed(makeDisabledContrastColor('colors.disabled')),
  shadow: computed(function (_ref14) {
    var colors = _ref14.colors;
    return colors.ink;
  })
}, brand_text_colors), {}, {
  // Text
  text: '#DFE5EF',
  title: computed(function (_ref15) {
    var _ref16 = _slicedToArray(_ref15, 1),
      text = _ref16[0];
    return text;
  }, ['colors.text']),
  subduedText: computed(makeHighContrastColor('colors.mediumShade')),
  link: computed(function (_ref17) {
    var _ref18 = _slicedToArray(_ref17, 1),
      primaryText = _ref18[0];
    return primaryText;
  }, ['colors.primaryText'])
});

/*
 * FULL
 */

export var colors = {
  ghost: '#FFF',
  ink: '#000',
  LIGHT: light_colors,
  DARK: dark_colors_ams
};