var _excluded = ["type", "isFocused", "isInvalid", "isDisabled", "children", "className"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiFormLabel = function EuiFormLabel(_ref) {
  var _ref$type = _ref.type,
    type = _ref$type === void 0 ? 'label' : _ref$type,
    isFocused = _ref.isFocused,
    isInvalid = _ref.isInvalid,
    isDisabled = _ref.isDisabled,
    children = _ref.children,
    className = _ref.className,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiFormLabel', className, {
    'euiFormLabel-isFocused': isFocused,
    'euiFormLabel-isInvalid': isInvalid,
    'euiFormLabel-isDisabled': isDisabled
  });
  if (type === 'legend') {
    return ___EmotionJSX("legend", _extends({
      className: classes
    }, rest), children);
  } else {
    return ___EmotionJSX("label", _extends({
      className: classes
    }, rest), children);
  }
};
EuiFormLabel.propTypes = {
  /**
     * Default type is a `label` but can be changed to a `legend`
     * if using inside a `fieldset`.
     */
  /**
     * Default type is a `label` but can be changed to a `legend`
     * if using inside a `fieldset`.
     */
  type: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOf(["label"]), PropTypes.oneOf(["label", "legend"])]), PropTypes.oneOfType([PropTypes.oneOf(["legend"]).isRequired, PropTypes.oneOf(["label", "legend"])])]),
  isFocused: PropTypes.bool,
  isInvalid: PropTypes.bool,
  /**
     * Changes `cursor` to `default`.
     */
  /**
     * Changes `cursor` to `default`.
     */
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};