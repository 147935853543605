/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useCurrentEuiBreakpoint } from '../../services';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiShowFor = function EuiShowFor(_ref) {
  var children = _ref.children,
    sizes = _ref.sizes;
  var currentBreakpoint = useCurrentEuiBreakpoint();
  var isWithinBreakpointSizes = currentBreakpoint && sizes.includes(currentBreakpoint);
  if (sizes === 'all' || isWithinBreakpointSizes) {
    return ___EmotionJSX(React.Fragment, null, children);
  }
  return null;
};
EuiShowFor.propTypes = {
  /**
     * Required otherwise nothing ever gets returned
     */
  children: PropTypes.node.isRequired,
  /**
     * List of all the responsive sizes to show the children for.
     * Array of #EuiBreakpointSize
     */
  sizes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any.isRequired).isRequired, PropTypes.oneOf(["all", "none"])]).isRequired
};