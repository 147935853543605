function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import chroma from 'chroma-js';
import { isColorDark, tint } from '../../services';
import { euiButtonColor, euiButtonFillColor } from '../../themes/amsterdam/global_styling/mixins';
import { chromaValid, parseColor } from '../color_picker/utils';
export var euiBadgeColors = function euiBadgeColors(euiThemeContext) {
  var euiTheme = euiThemeContext.euiTheme,
    colorMode = euiThemeContext.colorMode;
  return {
    // Colors shared between buttons and badges
    primary: euiButtonFillColor(euiThemeContext, 'primary'),
    success: euiButtonFillColor(euiThemeContext, 'success'),
    warning: euiButtonFillColor(euiThemeContext, 'warning'),
    danger: euiButtonFillColor(euiThemeContext, 'danger'),
    accent: euiButtonFillColor(euiThemeContext, 'accent'),
    disabled: euiButtonColor(euiThemeContext, 'disabled'),
    // Colors unique to badges
    default: getBadgeColors(euiThemeContext, euiTheme.colors.lightShade),
    // Hollow has a border and is used for autocompleters and beta badges
    hollow: _objectSpread(_objectSpread({}, getBadgeColors(euiThemeContext, euiTheme.colors.emptyShade)), {}, {
      borderColor: colorMode === 'DARK' ? tint(euiTheme.border.color, 0.15) : euiTheme.border.color
    }),
    // Colors used by beta and notification badges
    subdued: getBadgeColors(euiThemeContext, tint(euiTheme.colors.lightShade, 0.3)),
    accentText: getBadgeColors(euiThemeContext, euiTheme.colors.accentText)
  };
};
export var getBadgeColors = function getBadgeColors(euiThemeContext, backgroundColor) {
  var color = getTextColor(euiThemeContext, backgroundColor);
  return {
    backgroundColor: backgroundColor,
    color: color
  };
};
export var getTextColor = function getTextColor(_ref, bgColor) {
  var euiTheme = _ref.euiTheme;
  var textColor = isColorDark.apply(void 0, _toConsumableArray(chroma(bgColor).rgb())) ? euiTheme.colors.ghost : euiTheme.colors.ink;
  return textColor;
};
export var getColorContrast = function getColorContrast(textColor, color) {
  return chroma.contrast(textColor, color);
};
export var getIsValidColor = function getIsValidColor(color) {
  return chromaValid(parseColor(color || '') || '');
};