var _excluded = ["className", "onClick", "onClose", "closeButtonPosition", "side"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { EuiButtonIcon } from '../button';
import { euiFlyoutCloseButtonStyles } from './_flyout_close_button.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiFlyoutCloseButton = function EuiFlyoutCloseButton(_ref) {
  var className = _ref.className,
    _onClick = _ref.onClick,
    onClose = _ref.onClose,
    closeButtonPosition = _ref.closeButtonPosition,
    side = _ref.side,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiFlyout__closeButton', className);
  var styles = useEuiMemoizedStyles(euiFlyoutCloseButtonStyles);
  var cssStyles = [styles.euiFlyout__closeButton, styles[closeButtonPosition], closeButtonPosition === 'outside' && styles.outsideSide[side]];
  var ariaLabel = useEuiI18n('euiFlyoutCloseButton.ariaLabel', 'Close this dialog');
  return ___EmotionJSX(EuiButtonIcon, _extends({
    display: closeButtonPosition === 'outside' ? 'fill' : 'empty',
    iconType: "cross",
    color: "text",
    "aria-label": ariaLabel,
    "data-test-subj": "euiFlyoutCloseButton"
  }, rest, {
    className: classes,
    css: cssStyles,
    onClick: function onClick(e) {
      onClose(e.nativeEvent);
      _onClick === null || _onClick === void 0 || _onClick(e);
    }
  }));
};