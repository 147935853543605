var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(e, t) { return t || (t = e.slice(0)), Object.freeze(Object.defineProperties(e, { raw: { value: Object.freeze(t) } })); }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { keyframes } from '@emotion/react';
export var euiAnimFadeIn = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n  }\n\n  100% {\n    opacity: 1;\n  }\n"])));
export var euiAnimSlideInUp = function euiAnimSlideInUp(size) {
  return keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n   0% {\n    opacity: 0;\n    transform: translateY(", ");\n  }\n\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n"])), size);
};
export var euiAnimSlideX = function euiAnimSlideX(size) {
  return keyframes(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(", ");\n  }\n\n  100% {\n    transform: translateX(0);\n\n  }\n"])), size);
};
export var euiAnimScale = keyframes(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n  }\n\n  1% {\n    opacity: 0;\n    transform: scale(0);\n  }\n\n  100% {\n    opacity: 1;\n    transform: scale(1);\n  }\n"])));