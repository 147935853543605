function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { css } from '@emotion/react';
export var euiOverlayMaskBodyStyles = process.env.NODE_ENV === "production" ? {
  name: "131160-euiOverlayMaskBodyStyles",
  styles: "body{overflow:hidden;};label:euiOverlayMaskBodyStyles;"
} : {
  name: "131160-euiOverlayMaskBodyStyles",
  styles: "body{overflow:hidden;};label:euiOverlayMaskBodyStyles;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};