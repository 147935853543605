function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["children", "className", "sections", "position", "theme"],
  _excluded2 = ["children", "style"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme, useEuiThemeCSSVariables } from '../../services';
import { mathWithUnits, logicalStyles } from '../../global_styling';
import { EuiHeaderBreadcrumbs } from './header_breadcrumbs';
import { EuiHeaderSectionItem, EuiHeaderSection } from './header_section';
import { euiHeaderStyles, euiHeaderVariables } from './header.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var createHeaderSection = function createHeaderSection(sections) {
  return sections.map(function (section, index) {
    return ___EmotionJSX(EuiHeaderSectionItem, {
      key: index
    }, section);
  });
};
export var EuiHeader = function EuiHeader(_ref) {
  var children = _ref.children,
    className = _ref.className,
    sections = _ref.sections,
    _ref$position = _ref.position,
    position = _ref$position === void 0 ? 'static' : _ref$position,
    _ref$theme = _ref.theme,
    theme = _ref$theme === void 0 ? 'default' : _ref$theme,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiHeader', className);
  var euiTheme = useEuiTheme();
  var styles = euiHeaderStyles(euiTheme);
  var cssStyles = [styles.euiHeader, styles[position], styles[theme]];
  var contents;
  if (sections) {
    if (children) {
      // In case both children and sections are passed, warn in the console that the children will be disregarded
      console.warn('EuiHeader cannot accept both `children` and `sections`. It will disregard the `children`.');
    }
    contents = sections.map(function (section, index) {
      var content = [];
      if (section.items) {
        // Items get wrapped in EuiHeaderSection and each item in a EuiHeaderSectionItem
        content.push(___EmotionJSX(EuiHeaderSection, {
          key: "items-".concat(index)
        }, createHeaderSection(section.items)));
      }
      if (section.breadcrumbs) {
        content.push(
        // Breadcrumbs are separate and cannot be contained in a EuiHeaderSection
        // in order for truncation to work
        ___EmotionJSX(EuiHeaderBreadcrumbs, _extends({
          key: "breadcrumbs-".concat(index),
          breadcrumbs: section.breadcrumbs
        }, section.breadcrumbProps)));
      }
      return content;
    });
  } else {
    contents = children;
  }
  return position === 'fixed' ? ___EmotionJSX(EuiFixedHeader, _extends({
    css: cssStyles,
    className: classes
  }, rest), contents) : ___EmotionJSX("div", _extends({
    css: cssStyles,
    className: classes
  }, rest), contents);
};

/**
 * Fixed headers - logic around dynamically calculating the total
 * page offset and setting the `top` position of subsequent headers
 */

// Start a counter to manage the total number of fixed headers
// Exported for unit testing only
EuiHeader.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * An array of objects to wrap in a #EuiHeaderSection.
       * Each section is spaced using `space-between`.
       * See #EuiHeaderSectionsProp for object details.
       * This prop disregards the prop `children` if both are passed.
       */
  sections: PropTypes.arrayOf(PropTypes.shape({
    /**
       * An arry of items that will be wrapped in a #EuiHeaderSectionItem
       */
    items: PropTypes.arrayOf(PropTypes.node.isRequired),
    /**
       * Breadcrumbs in the header cannot be wrapped in a #EuiHeaderSection in order for truncation to work.
       * Simply pass the array of EuiBreadcrumb objects
       */
    breadcrumbs: PropTypes.arrayOf(PropTypes.any.isRequired),
    /**
       * Other props to pass to #EuiHeaderBreadcrumbs
       */
    breadcrumbProps: PropTypes.any
  }).isRequired),
  /**
       * Helper that positions the header against the window body and
       * adds the correct amount of top padding to the window when in `fixed` mode
       */
  position: PropTypes.oneOf(["static", "fixed"]),
  /**
       * The `default` will inherit its coloring from the light or dark theme.
       * Or, force the header into pseudo `dark` theme for all themes.
       */
  theme: PropTypes.oneOf(["default", "dark"])
};
export var euiFixedHeadersCount = 0;

// Exported for unit testing only
export var EuiFixedHeader = function EuiFixedHeader(_ref2) {
  var children = _ref2.children,
    style = _ref2.style,
    rest = _objectWithoutProperties(_ref2, _excluded2);
  var _useEuiThemeCSSVariab = useEuiThemeCSSVariables(),
    setGlobalCSSVariables = _useEuiThemeCSSVariab.setGlobalCSSVariables;
  var euiTheme = useEuiTheme();
  var headerHeight = euiHeaderVariables(euiTheme).height;
  var getHeaderOffset = useCallback(function () {
    return mathWithUnits(headerHeight, function (x) {
      return x * euiFixedHeadersCount;
    });
  }, [headerHeight]);
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    topPosition = _useState2[0],
    setTopPosition = _useState2[1];
  useEffect(function () {
    // Get the top position from the offset of previous header(s)
    setTopPosition(getHeaderOffset());

    // Increment fixed header counter for each fixed header
    euiFixedHeadersCount++;
    setGlobalCSSVariables({
      '--euiFixedHeadersOffset': getHeaderOffset()
    });
    document.body.classList.add('euiBody--headerIsFixed'); // TODO: Consider deleting this legacy className

    return function () {
      euiFixedHeadersCount--;
      setGlobalCSSVariables({
        '--euiFixedHeadersOffset': getHeaderOffset()
      });
      if (euiFixedHeadersCount === 0) {
        document.body.classList.remove('euiBody--headerIsFixed'); // TODO: Consider deleting this legacy className
      }
    };
  }, [getHeaderOffset, setGlobalCSSVariables]);
  var inlineStyles = useMemo(function () {
    return logicalStyles(_objectSpread({
      top: topPosition
    }, style));
  }, [topPosition, style]);
  return ___EmotionJSX("div", _extends({
    "data-fixed-header": true // Used by EuiFlyouts as a query selector
    ,
    style: inlineStyles
  }, rest), children);
};
EuiFixedHeader.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * An array of objects to wrap in a #EuiHeaderSection.
       * Each section is spaced using `space-between`.
       * See #EuiHeaderSectionsProp for object details.
       * This prop disregards the prop `children` if both are passed.
       */
  sections: PropTypes.arrayOf(PropTypes.shape({
    /**
       * An arry of items that will be wrapped in a #EuiHeaderSectionItem
       */
    items: PropTypes.arrayOf(PropTypes.node.isRequired),
    /**
       * Breadcrumbs in the header cannot be wrapped in a #EuiHeaderSection in order for truncation to work.
       * Simply pass the array of EuiBreadcrumb objects
       */
    breadcrumbs: PropTypes.arrayOf(PropTypes.any.isRequired),
    /**
       * Other props to pass to #EuiHeaderBreadcrumbs
       */
    breadcrumbProps: PropTypes.any
  }).isRequired),
  /**
       * Helper that positions the header against the window body and
       * adds the correct amount of top padding to the window when in `fixed` mode
       */
  position: PropTypes.oneOf(["static", "fixed"]),
  /**
       * The `default` will inherit its coloring from the light or dark theme.
       * Or, force the header into pseudo `dark` theme for all themes.
       */
  theme: PropTypes.oneOf(["default", "dark"])
};