var _excluded = ["isSelected", "children", "className", "disabled", "href", "target", "rel", "prepend", "append"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useContext } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { getSecureRelForTarget, useEuiMemoizedStyles } from '../../services';
import { validateHref } from '../../services/security/href_validator';
import { euiTabStyles, euiTabContentStyles } from './tab.styles';
import { EuiTabsContext } from './tabs_context';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiTab = function EuiTab(_ref) {
  var isSelected = _ref.isSelected,
    children = _ref.children,
    className = _ref.className,
    _disabled = _ref.disabled,
    href = _ref.href,
    target = _ref.target,
    rel = _ref.rel,
    prepend = _ref.prepend,
    append = _ref.append,
    rest = _objectWithoutProperties(_ref, _excluded);
  var _useContext = useContext(EuiTabsContext),
    size = _useContext.size,
    expand = _useContext.expand;
  var isHrefValid = !href || validateHref(href);
  var disabled = _disabled || !isHrefValid;

  // Keep CSS classnames for reference
  var classes = classNames('euiTab', className, {
    'euiTab-isSelected': isSelected
  });
  var tabStyles = useEuiMemoizedStyles(euiTabStyles);
  var cssTabStyles = [tabStyles.euiTab, expand && tabStyles.expanded, disabled && tabStyles.disabled.disabled, isSelected && (disabled ? tabStyles.disabled.selected : tabStyles.selected)];
  var tabContentStyles = useEuiMemoizedStyles(euiTabContentStyles);
  var cssTabContentStyles = [tabContentStyles.euiTab__content, size && tabContentStyles[size]];
  var prependNode = prepend && ___EmotionJSX("span", {
    className: "euiTab__prepend"
  }, prepend);
  var appendNode = append && ___EmotionJSX("span", {
    className: "euiTab__append"
  }, append);

  //  <a> elements don't respect the `disabled` attribute. So if we're disabled, we'll just pretend
  //  this is a button and piggyback off its disabled styles.
  if (href && !disabled) {
    var secureRel = getSecureRelForTarget({
      href: href,
      target: target,
      rel: rel
    });
    return ___EmotionJSX("a", _extends({
      role: "tab",
      "aria-selected": !!isSelected,
      className: classes,
      css: cssTabStyles,
      href: href,
      target: target,
      rel: secureRel
    }, rest), prependNode, ___EmotionJSX("span", {
      className: "euiTab__content",
      css: cssTabContentStyles
    }, children), appendNode);
  }
  return ___EmotionJSX("button", _extends({
    role: "tab",
    "aria-selected": !!isSelected,
    type: "button",
    className: classes,
    css: cssTabStyles,
    disabled: disabled
  }, rest), prependNode, ___EmotionJSX("span", {
    className: "euiTab__content eui-textTruncate",
    css: cssTabContentStyles
  }, children), appendNode);
};
EuiTab.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
     * Places content before the tab content/children.
     * Will be excluded from interactive effects.
     */
  /**
     * Places content before the tab content/children.
     * Will be excluded from interactive effects.
     */
  prepend: PropTypes.node,
  /**
     * Places content after the tab content/children.
     * Will be excluded from interactive effects.
     */
  /**
     * Places content after the tab content/children.
     * Will be excluded from interactive effects.
     */
  append: PropTypes.node,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  href: PropTypes.string,
  onClick: PropTypes.func
};