var _excluded = ["href", "target", "rel", "onClick", "className", "children", "disabled"],
  _excluded2 = ["isOpen", "isSelected", "isParent", "icon", "onClick", "href", "rel", "target", "items", "children", "renderItem", "depth", "className", "css", "style", "truncate", "emphasize", "buttonClassName", "childrenOnly"];
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useEuiTheme, getSecureRelForTarget } from '../../services';
import { validateHref } from '../../services/security/href_validator';
import { EuiInnerText } from '../inner_text';
import { EuiIcon } from '../icon';
import { euiSideNavItemStyles, euiSideNavItemButtonStyles } from './side_nav_item.styles';

/**
 * The props that are exposed to, or altered for, the consumer
 * for use in the object of items in `EuiSideNav`
 * can be found in the `side_nave_types.ts` file.
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
var DefaultRenderItem = function DefaultRenderItem(_ref) {
  var href = _ref.href,
    target = _ref.target,
    rel = _ref.rel,
    onClick = _ref.onClick,
    className = _ref.className,
    children = _ref.children,
    disabled = _ref.disabled,
    rest = _objectWithoutProperties(_ref, _excluded);
  if (href && !disabled) {
    var secureRel = getSecureRelForTarget({
      href: href,
      rel: rel,
      target: target
    });
    return ___EmotionJSX("a", _extends({
      className: className,
      href: href,
      target: target,
      rel: secureRel,
      onClick: onClick
    }, rest), children);
  }
  if (onClick || disabled) {
    return ___EmotionJSX("button", _extends({
      type: "button",
      className: className,
      onClick: onClick,
      disabled: disabled
    }, rest), children);
  }
  return ___EmotionJSX("div", _extends({
    className: className
  }, rest), children);
};
export var EuiSideNavItem = function EuiSideNavItem(_ref2) {
  var isOpen = _ref2.isOpen,
    isSelected = _ref2.isSelected,
    isParent = _ref2.isParent,
    icon = _ref2.icon,
    onClick = _ref2.onClick,
    _href = _ref2.href,
    rel = _ref2.rel,
    target = _ref2.target,
    items = _ref2.items,
    children = _ref2.children,
    _ref2$renderItem = _ref2.renderItem,
    RenderItem = _ref2$renderItem === void 0 ? DefaultRenderItem : _ref2$renderItem,
    _ref2$depth = _ref2.depth,
    depth = _ref2$depth === void 0 ? 0 : _ref2$depth,
    className = _ref2.className,
    css = _ref2.css,
    style = _ref2.style,
    _ref2$truncate = _ref2.truncate,
    truncate = _ref2$truncate === void 0 ? true : _ref2$truncate,
    emphasize = _ref2.emphasize,
    buttonClassName = _ref2.buttonClassName,
    childrenOnly = _ref2.childrenOnly,
    rest = _objectWithoutProperties(_ref2, _excluded2);
  var euiTheme = useEuiTheme();
  var isHrefValid = !_href || validateHref(_href);
  var href = isHrefValid ? _href : '';

  // Forcing accordion style item if not linked, but has children
  var _useState = useState(isOpen),
    _useState2 = _slicedToArray(_useState, 2),
    itemIsOpen = _useState2[0],
    setItemIsOpen = _useState2[1];
  useEffect(function () {
    setItemIsOpen(isOpen);
  }, [isOpen]);
  var toggleItemOpen = useCallback(function () {
    setItemIsOpen(function (isOpen) {
      return !isOpen;
    });
  }, []);
  var isRoot = depth === 0;
  var isTrunk = depth === 1;
  var isBranch = depth > 1;
  var hasCaret = depth > 0 && childrenOnly;
  var hasChildItems = items && itemIsOpen;
  var classes = classNames('euiSideNavItem', {
    'euiSideNavItem--root': isRoot,
    'euiSideNavItem--trunk': isTrunk,
    'euiSideNavItem--branch': isBranch,
    'euiSideNavItem--emphasized': emphasize,
    'euiSideNavItem-hasChildItems': hasChildItems
  }, className);
  var styles = euiSideNavItemStyles(euiTheme);
  var cssStyles = [styles.euiSideNavItem, isRoot && styles.root, isTrunk && styles.trunk, isBranch && styles.branch, emphasize && styles.emphasized, css];
  var itemsStyles = hasChildItems && [styles.items.euiSideNavItem__items, isRoot && icon && styles.items.rootWithIcon, isTrunk && styles.items.trunk, isBranch && styles.items.branch];
  var buttonClasses = classNames('euiSideNavItemButton', {
    'euiSideNavItemButton-isOpen': depth > 0 && itemIsOpen && !isSelected,
    'euiSideNavItemButton-isSelected': isSelected
  }, buttonClassName);
  var buttonStyles = euiSideNavItemButtonStyles(euiTheme);
  var buttonCssStyles = [buttonStyles.euiSideNavItemButton, isSelected && buttonStyles.selected, emphasize && buttonStyles.emphasized, isRoot && buttonStyles.root, isTrunk && buttonStyles.trunk, isBranch && buttonStyles.branch];
  var labelCssStyles = [buttonStyles.label.euiSideNavItemButton__label, isRoot && buttonStyles.label.root];
  return ___EmotionJSX("div", {
    css: cssStyles,
    className: classes,
    style: style
  }, ___EmotionJSX(RenderItem, _extends({
    css: buttonCssStyles,
    className: buttonClasses,
    href: href,
    rel: rel,
    target: target,
    onClick: childrenOnly ? toggleItemOpen : onClick
  }, rest), ___EmotionJSX("span", {
    css: buttonStyles.euiSideNavItemButton__content,
    className: "euiSideNavItemButton__content"
  }, icon, ___EmotionJSX(EuiInnerText, null, function (ref, innerText) {
    return ___EmotionJSX("span", {
      ref: ref,
      title: truncate ? innerText : undefined,
      css: labelCssStyles,
      className: classNames('euiSideNavItemButton__label', {
        'eui-textTruncate': truncate
      })
    }, children);
  }), hasCaret && ___EmotionJSX(EuiIcon, {
    type: itemIsOpen ? 'arrowDown' : 'arrowRight',
    size: "s"
  }))), hasChildItems && ___EmotionJSX("div", {
    css: itemsStyles,
    className: "euiSideNavItem__items"
  }, items));
};