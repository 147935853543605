var _excluded = ["title", "titleId"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

// THIS IS A GENERATED FILE. DO NOT MODIFY MANUALLY. @see scripts/compile-icons.js

import * as React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var EuiIconPayment = function EuiIconPayment(_ref) {
  var title = _ref.title,
    titleId = _ref.titleId,
    props = _objectWithoutProperties(_ref, _excluded);
  return ___EmotionJSX("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: 16,
    height: 16,
    fill: "none",
    viewBox: "0 0 16 16",
    "aria-labelledby": titleId
  }, props), title ? ___EmotionJSX("title", {
    id: titleId
  }, title) : null, ___EmotionJSX("path", {
    d: "M.586 2.586A2 2 0 0 0 0 4h1a1 1 0 0 1 1-1V2a2 2 0 0 0-1.414.586ZM2 2h10.5a.5.5 0 0 1 0 1H2V2ZM0 4h1v6.5a.5.5 0 0 1-1 0V4Zm2.586.586A2 2 0 0 0 2 6h1a1 1 0 0 1 1-1V4a2 2 0 0 0-1.414.586Zm0 8.828A2 2 0 0 1 2 12h1a1 1 0 0 0 1 1v1a2 2 0 0 1-1.414-.586Zm12.828-8.828A2 2 0 0 1 16 6h-1a1 1 0 0 0-1-1V4a2 2 0 0 1 1.414.586Zm0 8.828A2 2 0 0 0 16 12h-1a1 1 0 0 1-1 1v1a2 2 0 0 0 1.414-.586ZM4 4h10v1H4zM3 7h12v1H3zm1 6h10v1H4zM2 6h1v6H2zm13 0h1v6h-1zm-5.5 4a.5.5 0 0 1 0 1H7.496a.5.5 0 0 1 0-1H9.5Zm4 0a.5.5 0 0 1 0 1h-2.004a.5.5 0 0 1 0-1H13.5Z"
  }));
};
export var icon = EuiIconPayment;