function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { css } from '@emotion/react';
export var euiTextTruncateStyles = {
  euiTextTruncate: process.env.NODE_ENV === "production" ? {
    name: "7o5qnz-euiTextTruncate",
    styles: "position:relative;overflow:hidden;white-space:nowrap;label:euiTextTruncate;"
  } : {
    name: "7o5qnz-euiTextTruncate",
    styles: "position:relative;overflow:hidden;white-space:nowrap;label:euiTextTruncate;",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
  },
  /**
   * The below CSS is a hack to get double clicking and selecting the *full* text
   * instead of the truncated text (useful for copying/pasting, and mimics how
   * `text-overflow: ellipsis` works).
   *
   * Real talk: I'm lowkey amazed it works and it wouldn't surprise me if we ran into
   * cross-browser issues with this at some point. Hopefully CSS natively implements
   * custom text truncation some day (https://github.com/w3c/csswg-drafts/issues/3937)
   * and there'll be no need for the entire component at that point 🙏
   */
  // Makes the truncated text unselectable/un-clickable
  euiTextTruncate__truncatedText: process.env.NODE_ENV === "production" ? {
    name: "1sjxkb1-euiTextTruncate__truncatedText",
    styles: "user-select:none;pointer-events:none;label:euiTextTruncate__truncatedText;"
  } : {
    name: "1sjxkb1-euiTextTruncate__truncatedText",
    styles: "user-select:none;pointer-events:none;label:euiTextTruncate__truncatedText;",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
  },
  // Positions the full text on top of the truncated text (so that clicking targets it)
  // and gives it a color opacity of 0 so that it's not actually visible
  euiTextTruncate__fullText: process.env.NODE_ENV === "production" ? {
    name: "19c9gqi-euiTextTruncate__fullText",
    styles: "position:absolute;inset:0;overflow:hidden;color:rgba(0, 0, 0, 0);@supports (-webkit-hyphens: none){text-overflow:ellipsis;};label:euiTextTruncate__fullText;"
  } : {
    name: "19c9gqi-euiTextTruncate__fullText",
    styles: "position:absolute;inset:0;overflow:hidden;color:rgba(0, 0, 0, 0);@supports (-webkit-hyphens: none){text-overflow:ellipsis;};label:euiTextTruncate__fullText;",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
  }
};