{
  "height": "block-size",
  "width": "inline-size",
  "max-height": "max-block-size",
  "max-width": "max-inline-size",
  "min-height": "min-block-size",
  "min-width": "min-inline-size",
  "top": "inset-block-start",
  "right": "inset-inline-end",
  "bottom": "inset-block-end",
  "left": "inset-inline-start",
  "horizontal": "inset-inline",
  "vertical": "inset-block",
  "margin-left": "margin-inline-start",
  "margin-right": "margin-inline-end",
  "margin-top": "margin-block-start",
  "margin-bottom": "margin-block-end",
  "margin-horizontal": "margin-inline",
  "margin-vertical": "margin-block",
  "padding-left": "padding-inline-start",
  "padding-right": "padding-inline-end",
  "padding-top": "padding-block-start",
  "padding-bottom": "padding-block-end",
  "padding-horizontal": "padding-inline",
  "padding-vertical": "padding-block",
  "overflow-x": "overflow-inline",
  "overflow-y": "overflow-block",
  "border-horizontal": "border-inline",
  "border-horizontal-color": "border-inline-color",
  "border-horizontal-width": "border-inline-width",
  "border-horizontal-style": "border-inline-style",
  "border-vertical": "border-block",
  "border-vertical-color": "border-block-color",
  "border-vertical-width": "border-block-width",
  "border-vertical-style": "border-block-style",
  "border-bottom": "border-block-end",
  "border-bottom-color": "border-block-end-color",
  "border-bottom-style": "border-block-end-style",
  "border-bottom-width": "border-block-end-width",
  "border-top": "border-block-start",
  "border-top-color": "border-block-start-color",
  "border-top-style": "border-block-start-style",
  "border-top-width": "border-block-start-width",
  "border-right": "border-inline-end",
  "border-right-color": "border-inline-end-color",
  "border-right-style": "border-inline-end-style",
  "border-right-width": "border-inline-end-width",
  "border-left": "border-inline-start",
  "border-left-color": "border-inline-start-color",
  "border-left-style": "border-inline-start-style",
  "border-left-width": "border-inline-start-width",
  "border-top-left-radius": "border-start-start-radius",
  "border-top-right-radius": "border-start-end-radius",
  "border-bottom-left-radius": "border-end-start-radius",
  "border-bottom-right-radius": "border-end-end-radius",
  "_shorthands": [
    "margin",
    "scroll-margin",
    "padding",
    "scroll-padding",
    "inset",
    "border-color",
    "border-width",
    "border-style",
    "border-radius"
  ]
}
