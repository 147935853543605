/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { cloneElementWithCss } from '../../../services';
import { euiScreenReaderOnlyStyles as styles } from './screen_reader_only.styles';
export var EuiScreenReaderOnly = function EuiScreenReaderOnly(_ref) {
  var children = _ref.children,
    className = _ref.className,
    showOnFocus = _ref.showOnFocus;
  var classes = classNames(className, children.props.className);
  var props = useMemo(function () {
    return {
      className: classes.length ? classes : undefined,
      css: showOnFocus ? styles['euiScreenReaderOnly-showOnFocus'] : styles.euiScreenReaderOnly
    };
  }, [classes, showOnFocus]);
  return cloneElementWithCss(children, props);
};
EuiScreenReaderOnly.propTypes = {
  /**
     * ReactElement to render as this component's content
     */
  children: PropTypes.element.isRequired,
  /**
     * For keyboard navigation, force content to display visually upon focus/focus-within.
     */
  showOnFocus: PropTypes.bool,
  className: PropTypes.string
};