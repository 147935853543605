/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import { EuiThemeProvider } from '../../services';
import { emitEuiProviderWarning } from '../../services/theme/warning';
import { cache as fallbackCache } from '../../services/emotion/css';
import { EuiGlobalStyles } from '../../global_styling/reset/global_styles';
import { EuiUtilityClasses } from '../../global_styling/utility/utility';
import { EuiThemeAmsterdam } from '../../themes';
import { EuiCacheProvider } from './cache';
import { EuiProviderNestedCheck, useIsNestedEuiProvider } from './nested';
import { EuiComponentDefaultsProvider } from './component_defaults';
import { jsx as ___EmotionJSX } from "@emotion/react";
var isEmotionCacheObject = function isEmotionCacheObject(obj) {
  return obj.hasOwnProperty('key');
};
export var EuiProvider = function EuiProvider(_ref) {
  var _defaultCache;
  var _ref$cache = _ref.cache,
    cache = _ref$cache === void 0 ? fallbackCache : _ref$cache,
    _ref$theme = _ref.theme,
    theme = _ref$theme === void 0 ? EuiThemeAmsterdam : _ref$theme,
    _ref$globalStyles = _ref.globalStyles,
    Globals = _ref$globalStyles === void 0 ? EuiGlobalStyles : _ref$globalStyles,
    _ref$utilityClasses = _ref.utilityClasses,
    Utilities = _ref$utilityClasses === void 0 ? EuiUtilityClasses : _ref$utilityClasses,
    colorMode = _ref.colorMode,
    modify = _ref.modify,
    componentDefaults = _ref.componentDefaults,
    children = _ref.children;
  var isNested = useIsNestedEuiProvider();
  if (isNested) {
    var providerMessage = "`EuiProvider` should not be nested or used more than once, other than at the top level of your app.\n    Use `EuiThemeProvider` instead for nested component-level theming: https://ela.st/euiprovider.";
    emitEuiProviderWarning(providerMessage);
    return children;
  }
  var defaultCache;
  var globalCache;
  var utilityCache;
  if (cache) {
    if (isEmotionCacheObject(cache)) {
      cache.compat = true;
      defaultCache = cache;
    } else {
      if (cache.default) {
        cache.default.compat = true;
      }
      defaultCache = cache.default;
      if (cache.global) {
        cache.global.compat = true;
      }
      globalCache = cache.global;
      if (cache.utility) {
        cache.utility.compat = true;
      }
      utilityCache = cache.utility;
    }
  }
  return ___EmotionJSX(EuiProviderNestedCheck, null, ___EmotionJSX(EuiCacheProvider, {
    cache: (_defaultCache = defaultCache) !== null && _defaultCache !== void 0 ? _defaultCache : fallbackCache
  }, ___EmotionJSX(EuiThemeProvider, {
    theme: theme !== null && theme !== void 0 ? theme : undefined,
    colorMode: colorMode,
    modify: modify
  }, theme && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EuiCacheProvider, {
    cache: globalCache,
    children: Globals && ___EmotionJSX(Globals, null)
  }), ___EmotionJSX(EuiCacheProvider, {
    cache: utilityCache,
    children: Utilities && ___EmotionJSX(Utilities, null)
  })), ___EmotionJSX(EuiComponentDefaultsProvider, {
    componentDefaults: componentDefaults
  }, children))));
};