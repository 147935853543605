var _excluded = ["size", "color", "grow", "textAlign", "children", "className"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { euiTextStyles } from './text.styles';
import { EuiTextColor } from './text_color';
import { EuiTextAlign } from './text_align';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TEXT_SIZES = ['xs', 's', 'm', 'relative'];
export var EuiText = function EuiText(_ref) {
  var _ref$size = _ref.size,
    size = _ref$size === void 0 ? 'm' : _ref$size,
    color = _ref.color,
    _ref$grow = _ref.grow,
    grow = _ref$grow === void 0 ? true : _ref$grow,
    textAlign = _ref.textAlign,
    children = _ref.children,
    className = _ref.className,
    rest = _objectWithoutProperties(_ref, _excluded);
  var styles = useEuiMemoizedStyles(euiTextStyles);
  var cssStyles = [styles.euiText, !grow ? styles.constrainedWidth : undefined, styles[size]];
  var classes = classNames('euiText', className);
  var text = ___EmotionJSX("div", _extends({
    css: cssStyles,
    className: classes
  }, rest), children);
  if (color) {
    text = ___EmotionJSX(EuiTextColor, {
      color: color,
      className: classes,
      cloneElement: true
    }, text);
  }
  if (textAlign) {
    text = ___EmotionJSX(EuiTextAlign, {
      textAlign: textAlign,
      className: classes,
      cloneElement: true
    }, text);
  }
  return text;
};
EuiText.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  textAlign: PropTypes.any,
  /**
       * Determines the text size. Choose `relative` to control the `font-size` based on the value of a parent container.
       */
  size: PropTypes.any,
  /**
       * Any of our named colors or a `hex`, `rgb` or `rgba` value.
       * @default inherit
       */
  color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  grow: PropTypes.bool
};