function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import escapeRegExp from 'lodash/escapeRegExp';
import { jsx as ___EmotionJSX } from "@emotion/react";
/**
 * Internal subcomponent with logic for highlighting all occurrences
 * of a search value within a subject
 *
 * Uses regex rather than indexOf/while loops for easier dev maintainability
 */
export var HighlightAll = function HighlightAll(_ref) {
  var searchSubject = _ref.searchSubject,
    _searchValue = _ref.searchValue,
    isStrict = _ref.isStrict,
    _ref$highlightCompone = _ref.highlightComponent,
    HighlightComponent = _ref$highlightCompone === void 0 ? 'mark' : _ref$highlightCompone;
  var searchValue = useMemo(function () {
    return Array.isArray(_searchValue) ? _searchValue.map(escapeRegExp).join('|') : escapeRegExp(_searchValue);
  }, [_searchValue]);
  var chunks = useMemo(function () {
    var regex = new RegExp(searchValue, isStrict ? 'g' : 'gi');
    var matches = _toConsumableArray(searchSubject.matchAll(regex)).map(function (match) {
      return {
        start: match.index || 0,
        end: (match.index || 0) + match[0].length
      };
    });
    return fillInChunks(matches, searchSubject.length);
  }, [searchValue, searchSubject, isStrict]);
  return ___EmotionJSX(React.Fragment, null, chunks.map(function (chunk) {
    var end = chunk.end,
      highlight = chunk.highlight,
      start = chunk.start;
    var value = searchSubject.substring(start, end);
    return highlight ? ___EmotionJSX(HighlightComponent, {
      key: start
    }, value) : value;
  }));
};

/**
 * Chunk utility
 */
HighlightAll.propTypes = {
  searchValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]).isRequired,
  searchSubject: PropTypes.string.isRequired,
  isStrict: PropTypes.bool.isRequired,
  highlightComponent: PropTypes.any
};
var fillInChunks = function fillInChunks(chunksToHighlight, totalLength) {
  var allChunks = [];
  var append = function append(start, end, highlight) {
    if (end - start > 0) {
      allChunks.push({
        start: start,
        end: end,
        highlight: highlight
      });
    }
  };
  if (chunksToHighlight.length === 0) {
    append(0, totalLength, false);
  } else {
    var lastIndex = 0;
    chunksToHighlight.forEach(function (chunk) {
      append(lastIndex, chunk.start, false);
      append(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, false);
  }
  return allChunks;
};