function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["className", "children", "initialFocus", "onClose", "maxWidth", "role", "style"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { keys, useEuiTheme } from '../../services';
import { isDOMNode } from '../../utils';
import { EuiButtonIcon } from '../button';
import { EuiFocusTrap } from '../focus_trap';
import { EuiOverlayMask } from '../overlay_mask';
import { EuiI18n } from '../i18n';
import { euiModalStyles } from './modal.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiModal = function EuiModal(_ref) {
  var className = _ref.className,
    children = _ref.children,
    initialFocus = _ref.initialFocus,
    onClose = _ref.onClose,
    _ref$maxWidth = _ref.maxWidth,
    maxWidth = _ref$maxWidth === void 0 ? true : _ref$maxWidth,
    _ref$role = _ref.role,
    role = _ref$role === void 0 ? 'dialog' : _ref$role,
    style = _ref.style,
    rest = _objectWithoutProperties(_ref, _excluded);
  var onKeyDown = function onKeyDown(event) {
    if (event.key === keys.ESCAPE) {
      if (isDOMNode(event.target) && event.currentTarget.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
        onClose(event);
      }
    }
  };
  var newStyle = style;
  if (typeof maxWidth !== 'boolean') {
    newStyle = _objectSpread(_objectSpread({}, newStyle), {}, {
      maxInlineSize: maxWidth
    });
  }
  var classes = classnames('euiModal', className);
  var euiTheme = useEuiTheme();
  var styles = euiModalStyles(euiTheme);
  var cssStyles = [styles.euiModal, maxWidth === true && styles.defaultMaxWidth];
  var cssCloseIconStyles = [styles.euiModal__closeIcon];
  return ___EmotionJSX(EuiOverlayMask, null, ___EmotionJSX(EuiFocusTrap, {
    initialFocus: initialFocus,
    scrollLock: true,
    preventScrollOnFocus: true
  }, ___EmotionJSX("div", _extends({
    css: cssStyles,
    className: classes,
    onKeyDown: onKeyDown,
    tabIndex: 0,
    style: newStyle,
    role: role,
    "aria-modal": true
  }, rest), ___EmotionJSX(EuiI18n, {
    token: "euiModal.closeModal",
    default: "Closes this modal window"
  }, function (closeModal) {
    return ___EmotionJSX(EuiButtonIcon, {
      iconType: "cross",
      onClick: onClose,
      css: cssCloseIconStyles,
      className: "euiModal__closeIcon",
      color: "text",
      "aria-label": closeModal
    });
  }), children)));
};
EuiModal.propTypes = {
  className: PropTypes.string,
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  /**
     * Sets the max-width of the modal.
     * Set to `true` to use the default (`euiBreakpoints 'm'`),
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  maxWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Specifies what element should initially have focus.
     * Can be a DOM node, or a selector string (which will be passed to document.querySelector() to find the DOM node), or a function that returns a DOM node.
     */
  initialFocus: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.func.isRequired, PropTypes.string.isRequired]),
  /**
     * Identifies a modal dialog to screen readers. Modal dialogs that confirm destructive actions
     * or need a user's attention should use "alertdialog".
     */
  role: PropTypes.oneOf(["dialog", "alertdialog"])
};