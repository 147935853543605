var _excluded = ["children", "restrictWidth", "className", "paddingSize", "grow", "direction"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { setStyleForRestrictedPageWidth } from './_restrict_width';
import { useEuiPaddingCSS } from '../../global_styling';
import { euiPageStyles } from './page.styles';
import { useEuiTheme } from '../../services';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiPage = function EuiPage(_ref) {
  var children = _ref.children,
    _ref$restrictWidth = _ref.restrictWidth,
    restrictWidth = _ref$restrictWidth === void 0 ? false : _ref$restrictWidth,
    className = _ref.className,
    _ref$paddingSize = _ref.paddingSize,
    paddingSize = _ref$paddingSize === void 0 ? 'none' : _ref$paddingSize,
    _ref$grow = _ref.grow,
    grow = _ref$grow === void 0 ? true : _ref$grow,
    _ref$direction = _ref.direction,
    direction = _ref$direction === void 0 ? 'row' : _ref$direction,
    rest = _objectWithoutProperties(_ref, _excluded);
  // Set max-width as a style prop
  var widthStyles = setStyleForRestrictedPageWidth(restrictWidth, rest === null || rest === void 0 ? void 0 : rest.style);
  var euiTheme = useEuiTheme();
  var styles = euiPageStyles(euiTheme);
  var padding = useEuiPaddingCSS()[paddingSize];
  var stylesCSS = [styles.euiPage, styles[direction], grow && styles.grow, padding, restrictWidth && styles.restrictWidth];
  var classes = classNames('euiPage', className);
  return ___EmotionJSX("div", _extends({
    css: stylesCSS,
    className: classes
  }, rest, {
    style: widthStyles
  }), children);
};
EuiPage.propTypes = {
  /**
     * Adjust the padding.
     * When using this setting it's best to be consistent throughout all similar usages
     */
  paddingSize: PropTypes.any,
  /**
     * Adds `flex-grow: 1` to the whole page for stretching to fit vertically.
     * Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`
     */
  grow: PropTypes.bool,
  /**
     * Changes the `flex-direction` property.
     * Flip to `column` when not including a sidebar.
     */
  direction: PropTypes.oneOf(["row", "column"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired])
};