var _excluded = ["children", "className", "banner", "scrollableTabIndex"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { euiFlyoutBodyStyles } from './flyout_body.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiFlyoutBody = function EuiFlyoutBody(_ref) {
  var children = _ref.children,
    className = _ref.className,
    banner = _ref.banner,
    _ref$scrollableTabInd = _ref.scrollableTabIndex,
    scrollableTabIndex = _ref$scrollableTabInd === void 0 ? 0 : _ref$scrollableTabInd,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiFlyoutBody', className);
  var styles = useEuiMemoizedStyles(euiFlyoutBodyStyles);
  var overflowCssStyles = [styles.overflow.euiFlyoutBody__overflow, banner ? styles.overflow.hasBanner : styles.overflow.noBanner];
  return ___EmotionJSX("div", _extends({
    className: classes,
    css: styles.euiFlyoutBody
  }, rest), ___EmotionJSX("div", {
    tabIndex: scrollableTabIndex,
    className: "euiFlyoutBody__overflow",
    css: overflowCssStyles
  }, banner && ___EmotionJSX("div", {
    className: "euiFlyoutBody__banner",
    css: styles.euiFlyoutBody__banner
  }, banner), ___EmotionJSX("div", {
    className: "euiFlyoutBody__overflowContent"
  }, children)));
};
EuiFlyoutBody.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
         * Use to display a banner at the top of the body. It is suggested to use `EuiCallOut` for it.
         */
  banner: PropTypes.node,
  /**
         * [Scrollable regions (or their children) should be focusable](https://dequeuniversity.com/rules/axe/4.0/scrollable-region-focusable)
         * to allow keyboard users to scroll the region via arrow keys.
         *
         * By default, EuiFlyoutBody's scroll overflow wrapper sets a `tabIndex` of `0`.
         * If you know your flyout body content already contains focusable children
         * that satisfy keyboard accessibility requirements, you can use this prop
         * to override this default.
         */
  scrollableTabIndex: PropTypes.number
};