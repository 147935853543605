var _excluded = ["title", "titleId"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

// THIS IS A GENERATED FILE. DO NOT MODIFY MANUALLY. @see scripts/compile-icons.js

import * as React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var EuiIconCheer = function EuiIconCheer(_ref) {
  var title = _ref.title,
    titleId = _ref.titleId,
    props = _objectWithoutProperties(_ref, _excluded);
  return ___EmotionJSX("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: 16,
    height: 16,
    viewBox: "0 0 16 16",
    "aria-labelledby": titleId
  }, props), title ? ___EmotionJSX("title", {
    id: titleId
  }, title) : null, ___EmotionJSX("path", {
    d: "M4.934 3.063a1.5 1.5 0 0 1 .547.321l.112.115 6.07 6.915a1.5 1.5 0 0 1-.646 2.41l-.142.04-9.031 2.097A1.5 1.5 0 0 1 .037 13.19l.043-.159L3.04 4.02a1.5 1.5 0 0 1 1.893-.957ZM4.027 4.25l-.036.083-2.961 9.011a.5.5 0 0 0 .498.656l.09-.013 2.937-.681-1.399-1.508a.5.5 0 0 1 .666-.74l.067.06 1.788 1.927 2.634-.611-3.198-3.601a.5.5 0 0 1 .682-.726l.066.062 3.559 4.007 1.229-.284a.5.5 0 0 0 .15-.063l.067-.049a.5.5 0 0 0 .099-.632l-.053-.073-6.07-6.916a.5.5 0 0 0-.138-.11l-.082-.035-.088-.02a.5.5 0 0 0-.507.256Zm11.66 5.039a2.5 2.5 0 0 1-.975 3.399.5.5 0 0 1-.485-.875 1.5 1.5 0 0 0-1.454-2.624.5.5 0 0 1-.485-.875 2.5 2.5 0 0 1 3.399.975Zm-5.03-6.206a.5.5 0 0 1 .338.544l-.02.088-.677 2.035 2.068-.721a.5.5 0 0 1 .6.225l.036.082a.5.5 0 0 1-.225.6l-.082.037L9.67 7.028a.5.5 0 0 1-.659-.55l.02-.08.995-3a.5.5 0 0 1 .632-.316ZM14.5 4a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1ZM7.862.403a2.5 2.5 0 0 1 .735 3.459.5.5 0 0 1-.839-.545 1.5 1.5 0 1 0-2.516-1.634.5.5 0 0 1-.839-.545A2.5 2.5 0 0 1 7.862.403ZM13.5 2a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm-3-1a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm4-1a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Z"
  }));
};
export var icon = EuiIconCheer;