var _excluded = ["text", "highlightSearchString", "className"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { RenderWithEuiStylesMemoizer } from '../../../services';
import { EuiIcon } from '../../icon';
import { EuiAvatar } from '../../avatar';
import { EuiHighlight } from '../../highlight';
import { euiSelectableTemplateSitewideStyles } from './selectable_template_sitewide.styles';

/**
 * The generic extension allows consumers to keep their data objects
 * intact without needing to do key lookups when using `renderOption`
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export var euiSelectableTemplateSitewideFormatOptions = function euiSelectableTemplateSitewideFormatOptions(options, styles) {
  return options.map(function (item) {
    var title = item.label;
    if (item.meta && item.meta.length) {
      title += " \u2022".concat(renderOptionMeta({
        meta: item.meta,
        stringsOnly: true,
        styles: styles
      }));
    }
    return _objectSpread(_objectSpread({
      key: item.label,
      title: title
    }, item), {}, {
      css: [styles.euiSelectableTemplateSitewide__listItem, item.css],
      className: classNames('euiSelectableTemplateSitewide__listItem', item.className),
      prepend: item.icon ? ___EmotionJSX(EuiIcon, _extends({
        color: "subdued",
        size: "l"
      }, item.icon)) : item.prepend,
      append: item.avatar ? ___EmotionJSX(EuiAvatar, _extends({
        type: "space",
        size: "s"
      }, item.avatar)) : item.append
    });
  });
};
export var euiSelectableTemplateSitewideRenderOptions = function euiSelectableTemplateSitewideRenderOptions(option, searchValue) {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EuiHighlight, {
    className: "euiSelectableTemplateSitewide__listItemTitle",
    search: searchValue
  }, option.label), ___EmotionJSX(RenderWithEuiStylesMemoizer, null, function (stylesMemoizer) {
    var styles = stylesMemoizer(euiSelectableTemplateSitewideStyles);
    return renderOptionMeta({
      meta: option.meta,
      styles: styles,
      searchValue: searchValue
    });
  }));
};
var renderOptionMeta = function renderOptionMeta(_ref) {
  var meta = _ref.meta,
    styles = _ref.styles,
    _ref$searchValue = _ref.searchValue,
    searchValue = _ref$searchValue === void 0 ? '' : _ref$searchValue,
    _ref$stringsOnly = _ref.stringsOnly,
    stringsOnly = _ref$stringsOnly === void 0 ? false : _ref$stringsOnly;
  if (!meta || meta.length < 1) return;
  var metas = meta.map(function (meta) {
    var text = meta.text,
      highlightSearchString = meta.highlightSearchString,
      className = meta.className,
      rest = _objectWithoutProperties(meta, _excluded);
    if (stringsOnly) {
      return " ".concat(text);
    }

    // Start with the base and custom classes
    var metaClasses = classNames('euiSelectableTemplateSitewide__optionMeta', className);

    // If they provided a type, create the class and append
    if (meta.type) {
      metaClasses = classNames(["euiSelectableTemplateSitewide__optionMeta--".concat(meta.type)], metaClasses);
    }
    var hasMetaTypeStyles = function hasMetaTypeStyles(metaType) {
      return metaType in styles.metaTypes;
    };
    var cssStyles = [styles.euiSelectableTemplateSitewide__optionMeta].concat(_toConsumableArray(meta.type && hasMetaTypeStyles(meta.type) ? [styles.metaTypes.fontWeight, styles.metaTypes[meta.type]] : []));
    return ___EmotionJSX(EuiHighlight, _extends({
      search: highlightSearchString ? searchValue : '',
      css: cssStyles,
      className: metaClasses,
      key: text
    }, rest), text);
  });
  return stringsOnly ? metas : ___EmotionJSX("span", {
    css: styles.euiSelectableTemplateSitewide__optionMetasList,
    className: "euiSelectableTemplateSitewide__optionMetasList"
  }, metas);
};