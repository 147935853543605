var _excluded = ["className", "children", "headerZindexLocation", "maskRef"];
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { cx } from '@emotion/css';
import { Global } from '@emotion/react';
import { keysOf } from '../common';
import { useCombinedRefs, useEuiTheme } from '../../services';
import { EuiPortal } from '../portal';
import { euiOverlayMaskStyles } from './overlay_mask.styles';
import { euiOverlayMaskBodyStyles } from './overlay_mask_body.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiOverlayMask = function EuiOverlayMask(_ref) {
  var className = _ref.className,
    children = _ref.children,
    _ref$headerZindexLoca = _ref.headerZindexLocation,
    headerZindexLocation = _ref$headerZindexLoca === void 0 ? 'above' : _ref$headerZindexLoca,
    maskRef = _ref.maskRef,
    rest = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    overlayMaskNode = _useState2[0],
    setOverlayMaskNode = _useState2[1];
  var combinedMaskRef = useCombinedRefs([setOverlayMaskNode, maskRef]);
  var euiTheme = useEuiTheme();
  var styles = euiOverlayMaskStyles(euiTheme);
  var cssStyles = cx([styles.euiOverlayMask, styles["".concat(headerZindexLocation, "Header")]]);
  useEffect(function () {
    if (!overlayMaskNode) return;
    keysOf(rest).forEach(function (key) {
      if (typeof rest[key] !== 'string') {
        throw new Error("Unhandled property type. EuiOverlayMask property ".concat(key, " is not a string."));
      }
      if (overlayMaskNode) {
        overlayMaskNode.setAttribute(key, rest[key]);
      }
    });
  }, [overlayMaskNode]); // eslint-disable-line react-hooks/exhaustive-deps

  // Note: Use `classList.add/remove` instead of setting the entire `className`
  // so as not to override any existing classes set by `EuiPortal`
  useEffect(function () {
    if (overlayMaskNode) {
      overlayMaskNode.classList.add('euiOverlayMask', cssStyles);
      overlayMaskNode.dataset.relativeToHeader = headerZindexLocation;
      return function () {
        return overlayMaskNode.classList.remove(cssStyles);
      };
    }
  }, [overlayMaskNode, cssStyles, headerZindexLocation]);
  useEffect(function () {
    if (className && overlayMaskNode) {
      var _overlayMaskNode$clas;
      var classNameArgs = className.split(' '); // The `classList` API doesn't support multiple classes in the same string
      (_overlayMaskNode$clas = overlayMaskNode.classList).add.apply(_overlayMaskNode$clas, _toConsumableArray(classNameArgs));
      return function () {
        var _overlayMaskNode$clas2;
        return (_overlayMaskNode$clas2 = overlayMaskNode.classList).remove.apply(_overlayMaskNode$clas2, _toConsumableArray(classNameArgs));
      };
    }
  }, [overlayMaskNode, className]);
  return ___EmotionJSX(EuiPortal, {
    portalRef: combinedMaskRef
  }, ___EmotionJSX(Global, {
    styles: euiOverlayMaskBodyStyles
  }), children);
};
EuiOverlayMask.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,
  /**
     * Should the mask visually sit above or below the EuiHeader (controlled by z-index)
     */
  headerZindexLocation: PropTypes.oneOf(["above", "below"]),
  /**
     * React ref to be passed to the wrapping container
     */
  maskRef: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired])
};