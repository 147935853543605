/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { jsx as ___EmotionJSX } from "@emotion/react";
/**
 * Internal subcomponent with logic for highlighting only the first occurrence
 * of a search value within a subject
 *
 * Uses indexOf for performance (which does matter for, e.g. EuiSelectable searching)
 */
export var HighlightFirst = function HighlightFirst(_ref) {
  var searchSubject = _ref.searchSubject,
    searchValue = _ref.searchValue,
    isStrict = _ref.isStrict,
    _ref$highlightCompone = _ref.highlightComponent,
    HighlightComponent = _ref$highlightCompone === void 0 ? 'mark' : _ref$highlightCompone;
  if (Array.isArray(searchValue)) {
    throw new Error('Cannot parse multiple search strings without `highlightAll` enabled');
  }
  var normalizedSearchSubject = isStrict ? searchSubject : searchSubject.toLowerCase();
  var normalizedSearchValue = isStrict ? searchValue : searchValue.toLowerCase();
  var indexOfMatch = normalizedSearchSubject.indexOf(normalizedSearchValue);
  if (indexOfMatch === -1) {
    return ___EmotionJSX(React.Fragment, null, searchSubject);
  }
  var preMatch = searchSubject.substring(0, indexOfMatch);
  var match = searchSubject.substring(indexOfMatch, indexOfMatch + searchValue.length);
  var postMatch = searchSubject.substring(indexOfMatch + searchValue.length);
  return (
    // Note: React 16/17 will render empty strings in the DOM. The
    // `|| undefined` prevents this & keeps snapshots the same for all versions
    ___EmotionJSX(React.Fragment, null, preMatch || undefined, ___EmotionJSX(HighlightComponent, null, match), postMatch || undefined)
  );
};
HighlightFirst.propTypes = {
  searchValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]).isRequired,
  searchSubject: PropTypes.string.isRequired,
  isStrict: PropTypes.bool.isRequired,
  highlightComponent: PropTypes.any
};