function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { EuiScreenReaderOnly } from '../screen_reader_only';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiScreenReaderLive = function EuiScreenReaderLive(_ref) {
  var children = _ref.children,
    _ref$isActive = _ref.isActive,
    isActive = _ref$isActive === void 0 ? true : _ref$isActive,
    _ref$role = _ref.role,
    role = _ref$role === void 0 ? 'status' : _ref$role,
    _ref$ariaLive = _ref['aria-live'],
    ariaLive = _ref$ariaLive === void 0 ? 'polite' : _ref$ariaLive,
    _ref$focusRegionOnTex = _ref.focusRegionOnTextChange,
    focusRegionOnTextChange = _ref$focusRegionOnTex === void 0 ? false : _ref$focusRegionOnTex;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    toggle = _useState2[0],
    setToggle = _useState2[1];
  var focusRef = useRef(null);
  useEffect(function () {
    setToggle(function (toggle) {
      return !toggle;
    });
  }, [children]);
  useEffect(function () {
    if (focusRef.current !== null && focusRegionOnTextChange) {
      focusRef.current.focus();
    }
  }, [toggle, focusRegionOnTextChange]);
  return (
    /**
     * Intentionally uses two persistent live regions with oscillating content updates.
     * This resolves the problem of duplicate screen reader announcements in rapid succession
     * caused by React's virtual DOM behaviour (https://github.com/nvaccess/nvda/issues/7996#issuecomment-413641709)
     *
     * Adapted from https://github.com/alphagov/accessible-autocomplete/blob/a7106f03150941fc15e6c1ceb0a90e8872fa86ef/src/status.js
     * Debouncing was not needed for this case, but could prove to be useful for future use cases.
     * See also https://github.com/AlmeroSteyn/react-aria-live and https://github.com/dequelabs/ngA11y
     * for more examples of the double region approach.
     */
    ___EmotionJSX(EuiScreenReaderOnly, null, ___EmotionJSX("div", {
      ref: focusRef,
      tabIndex: focusRegionOnTextChange ? -1 : undefined
    }, ___EmotionJSX("div", {
      role: role,
      "aria-atomic": "true"
      // Setting `aria-hidden` and setting `aria-live` to "off" prevents
      // double announcements from VO when `focusRegionOnTextChange` is true
      ,
      "aria-hidden": toggle ? undefined : 'true',
      "aria-live": !toggle || focusRegionOnTextChange ? 'off' : ariaLive
    }, isActive && toggle ? children : ''), ___EmotionJSX("div", {
      role: role,
      "aria-atomic": "true",
      "aria-hidden": !toggle ? undefined : 'true',
      "aria-live": toggle || focusRegionOnTextChange ? 'off' : ariaLive
    }, isActive && !toggle ? children : '')))
  );
};
EuiScreenReaderLive.propTypes = {
  /**
     * Whether to make screen readers aware of the content
     */
  isActive: PropTypes.bool,
  /**
     * Content for screen readers to announce
     */
  children: PropTypes.node,
  /**
     * `role` attribute for both live regions.
     *
     * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions#roles_with_implicit_live_region_attributes
     */
  role: PropTypes.any,
  /**
     * `aria-live` attribute for both live regions
     */
  "aria-live": PropTypes.any,
  /**
     * On `children`/text change, the region will auto-focus itself, causing screen readers
     * to automatically read out the text content. This prop should primarily be used for
     * navigation or page changes, where programmatically resetting focus location back to
     * a certain part of the page is desired.
     */
  focusRegionOnTextChange: PropTypes.bool
};