/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

/**
 * Font units of measure
 */

export var EuiThemeFontUnits = ['rem', 'px', 'em'];
/*
 * Font scale
 */

export var EuiThemeFontScales = ['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];

/*
 * Font base settings
 */

/*
 * Font weights
 */

export var EuiThemeFontWeights = ['light', 'regular', 'medium', 'semiBold', 'bold'];

/**
 * Body / Base styles
 */

/**
 * Title styles
 */

/*
 * Font
 */