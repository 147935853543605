var _excluded = ["className", "restrictWidth", "paddingSize", "bottomBorder", "alignItems", "responsive", "children", "pageTitle", "pageTitleProps", "iconType", "iconProps", "tabs", "tabsProps", "breadcrumbs", "breadcrumbProps", "description", "rightSideItems", "rightSideGroupProps"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { useEuiPaddingCSS } from '../../../global_styling';
import { EuiPageHeaderContent } from './page_header_content';
import { euiPageHeaderStyles } from './page_header.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiPageHeader = function EuiPageHeader(_ref) {
  var className = _ref.className,
    _ref$restrictWidth = _ref.restrictWidth,
    restrictWidth = _ref$restrictWidth === void 0 ? false : _ref$restrictWidth,
    _ref$paddingSize = _ref.paddingSize,
    paddingSize = _ref$paddingSize === void 0 ? 'none' : _ref$paddingSize,
    bottomBorder = _ref.bottomBorder,
    alignItems = _ref.alignItems,
    _ref$responsive = _ref.responsive,
    responsive = _ref$responsive === void 0 ? true : _ref$responsive,
    children = _ref.children,
    pageTitle = _ref.pageTitle,
    pageTitleProps = _ref.pageTitleProps,
    iconType = _ref.iconType,
    iconProps = _ref.iconProps,
    tabs = _ref.tabs,
    tabsProps = _ref.tabsProps,
    breadcrumbs = _ref.breadcrumbs,
    breadcrumbProps = _ref.breadcrumbProps,
    description = _ref.description,
    rightSideItems = _ref.rightSideItems,
    rightSideGroupProps = _ref.rightSideGroupProps,
    rest = _objectWithoutProperties(_ref, _excluded);
  var useTheme = useEuiTheme();
  var styles = euiPageHeaderStyles(useTheme);
  var inlinePadding = useEuiPaddingCSS('horizontal');
  var cssStyles = [styles.euiPageHeader, inlinePadding[paddingSize], bottomBorder === 'extended' && styles.border];
  var classes = classNames('euiPageHeader', className);
  var contentProps = {
    restrictWidth: restrictWidth,
    paddingSize: paddingSize,
    // Set the bottom to false to ensure the forced one doesn't render
    // when we'll be rendering it at this parent
    bottomBorder: bottomBorder === 'extended' ? false : bottomBorder,
    alignItems: alignItems,
    responsive: responsive,
    pageTitle: pageTitle,
    pageTitleProps: pageTitleProps,
    iconType: iconType,
    iconProps: iconProps,
    tabs: tabs,
    tabsProps: tabsProps,
    description: description,
    rightSideItems: rightSideItems,
    rightSideGroupProps: rightSideGroupProps,
    breadcrumbs: breadcrumbs,
    breadcrumbProps: breadcrumbProps
  };
  return ___EmotionJSX("header", _extends({
    className: classes,
    css: cssStyles
  }, rest), ___EmotionJSX(EuiPageHeaderContent, contentProps, children));
};
EuiPageHeader.propTypes = {
  /**
     * Adjust the overall padding.
     */
  paddingSize: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Adds a bottom border to separate it from the content after;
     * Passing `extended` will ensure the border touches the sides of the parent container.
     */
  bottomBorder: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["extended"])])
};