export interface LogoProps {
  size?: number;
}

export function Logo({ size = 24 }: LogoProps) {
  return (
    <svg width={size} height={size} role="img" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="3" fill="#fed047" />
      <g fill="#642340" aria-label="SU">
        <path d="m4.9173 12q-0.77867 0-1.3547-0.26667-0.56534-0.26667-0.87467-0.75734-0.30933-0.49067-0.30933-1.152h1.152q0 0.52267 0.37333 0.84267 0.384 0.32 1.024 0.32 0.608 0 0.97067-0.32 0.36267-0.32 0.36267-0.84267 0-0.42667-0.23467-0.736-0.23467-0.32-0.64-0.43733l-1.0347-0.30933q-0.85334-0.24533-1.3227-0.82134-0.46933-0.58667-0.46933-1.3867 0-0.97067 0.64-1.5467 0.64-0.58667 1.728-0.58667 1.0773 0 1.7173 0.576 0.65067 0.56534 0.65067 1.536h-1.152q0-0.50133-0.33067-0.8-0.33067-0.29867-0.896-0.29867t-0.896 0.29867q-0.33067 0.288-0.33067 0.8 0 0.39467 0.23467 0.68267 0.23467 0.288 0.65067 0.40533l1.0667 0.32q0.832 0.24533 1.2907 0.84267 0.46933 0.59734 0.46933 1.44 0 0.66134-0.30933 1.1627-0.30933 0.49067-0.87467 0.768-0.55467 0.26667-1.3013 0.26667z" />
        <path d="m11.285 12q-1.12 0-1.728-0.608-0.608-0.61867-0.608-1.6747v-5.6107h1.152v5.6q0 0.59733 0.29867 0.93867 0.29867 0.34133 0.88534 0.34133 0.58667 0 0.88534-0.34133 0.29867-0.34134 0.29867-0.93867v-5.6h1.152v5.6107q0 1.0667-0.608 1.6747t-1.728 0.608z" />
      </g>
    </svg>
  );
}
