var _excluded = ["className", "options", "id", "name", "inputRef", "isInvalid", "fullWidth", "isLoading", "hasNoInitialSelection", "defaultValue", "compressed", "value", "prepend", "append", "onMouseUp", "disabled"],
  _excluded2 = ["text"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { useFormContext } from '../eui_form_context';
import { EuiFormControlLayout } from '../form_control_layout';
import { EuiValidatableControl } from '../validatable_control';
import { euiSelectStyles } from './select.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiSelect = function EuiSelect(props) {
  var _useFormContext = useFormContext(),
    defaultFullWidth = _useFormContext.defaultFullWidth;
  var className = props.className,
    _props$options = props.options,
    options = _props$options === void 0 ? [] : _props$options,
    id = props.id,
    name = props.name,
    inputRef = props.inputRef,
    isInvalid = props.isInvalid,
    _props$fullWidth = props.fullWidth,
    fullWidth = _props$fullWidth === void 0 ? defaultFullWidth : _props$fullWidth,
    _props$isLoading = props.isLoading,
    isLoading = _props$isLoading === void 0 ? false : _props$isLoading,
    _props$hasNoInitialSe = props.hasNoInitialSelection,
    hasNoInitialSelection = _props$hasNoInitialSe === void 0 ? false : _props$hasNoInitialSe,
    defaultValue = props.defaultValue,
    _props$compressed = props.compressed,
    compressed = _props$compressed === void 0 ? false : _props$compressed,
    _value = props.value,
    prepend = props.prepend,
    append = props.append,
    onMouseUp = props.onMouseUp,
    disabled = props.disabled,
    rest = _objectWithoutProperties(props, _excluded);
  // if this is injecting an empty option for `hasNoInitialSelection` then
  // value needs to fallback to an empty string to interact properly with `defaultValue`
  var value = hasNoInitialSelection ? _value !== null && _value !== void 0 ? _value : '' : _value;

  // React HTML input can not have both value and defaultValue properties.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  var selectDefaultValue = value != null ? undefined : defaultValue || '';
  var handleMouseUp = useCallback(function (e) {
    // Normalizes cross-browser mouse eventing by preventing propagation,
    // notably for use in conjunction with EuiOutsideClickDetector.
    // See https://github.com/elastic/eui/pull/1926 for full discussion on
    // rationale and alternatives should this intervention become problematic.
    e.nativeEvent.stopImmediatePropagation();
    onMouseUp === null || onMouseUp === void 0 || onMouseUp(e);
  }, [onMouseUp]);
  var classes = classNames('euiSelect', {
    'euiSelect-isLoading': isLoading
  }, className);
  var inGroup = !!(prepend || append);
  var styles = useEuiMemoizedStyles(euiSelectStyles);
  var cssStyles = [styles.euiSelect, compressed ? styles.compressed : styles.uncompressed, fullWidth ? styles.fullWidth : styles.formWidth, inGroup && styles.inGroup, styles.lineHeight.removePadding, inGroup ? compressed ? styles.lineHeight.inGroup.compressed : styles.lineHeight.inGroup.uncompressed : compressed ? styles.lineHeight.compressed : styles.lineHeight.uncompressed];
  return ___EmotionJSX(EuiFormControlLayout, {
    isDropdown: true,
    fullWidth: fullWidth,
    isLoading: isLoading,
    isInvalid: isInvalid,
    isDisabled: disabled,
    compressed: compressed,
    prepend: prepend,
    append: append,
    inputId: id
  }, ___EmotionJSX(EuiValidatableControl, {
    isInvalid: isInvalid
  }, ___EmotionJSX("select", _extends({
    id: id,
    name: name,
    className: classes,
    css: cssStyles,
    ref: inputRef,
    defaultValue: selectDefaultValue,
    value: value,
    onMouseUp: handleMouseUp,
    disabled: disabled
  }, rest), hasNoInitialSelection && ___EmotionJSX("option", {
    value: "",
    disabled: true,
    hidden: true,
    style: {
      display: 'none'
    }
  }, "\xA0"), options.map(function (option, index) {
    var text = option.text,
      rest = _objectWithoutProperties(option, _excluded2);
    return ___EmotionJSX("option", _extends({}, rest, {
      key: index
    }), text);
  }))));
};
EuiSelect.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * @default []
       */
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node.isRequired
  }).isRequired),
  isInvalid: PropTypes.bool,
  /**
       * Expand to fill 100% of the parent.
       * Defaults to `fullWidth` prop of `<EuiForm>`.
       * @default false
       */
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  /**
       * Simulates no selection by creating an empty, selected, hidden first option
       * @default false
       */
  hasNoInitialSelection: PropTypes.bool,
  inputRef: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  /**
       * when `true` creates a shorter height input
       * @default false
       */
  compressed: PropTypes.bool,
  /**
       * Creates an input group with element(s) coming before select.
       * `string` | `ReactElement` or an array of these
       */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
       * Creates an input group with element(s) coming after select.
       * `string` | `ReactElement` or an array of these
       */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired])
};