/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

var providerWarning = undefined;
export var setEuiDevProviderWarning = function setEuiDevProviderWarning(warningType) {
  return providerWarning = warningType;
};
export var getEuiDevProviderWarning = function getEuiDevProviderWarning() {
  return providerWarning;
};

// Not a public top-level EUI export, currently for internal use
export var emitEuiProviderWarning = function emitEuiProviderWarning(providerMessage) {
  // Handle callback types
  if (typeof providerWarning === 'function') {
    return providerWarning(providerMessage);
  }
  // Handle level types
  switch (providerWarning) {
    case 'log':
      console.log(providerMessage);
      break;
    case 'warn':
      console.warn(providerMessage);
      break;
    case 'error':
      throw new Error(providerMessage);
    case undefined:
    default:
      break;
  }
};